export const getDeviceInfo = async () => {
  try {
    let browser = "Unknown";
    let platform = "Unknown";

    if (navigator.userAgentData) {
      const uaData = await navigator.userAgentData.getHighEntropyValues([
        "platform",
        "brands",
      ]);

      platform = uaData.platform || "Unknown";

      if (uaData.brands && uaData.brands.length > 0) {
        browser = uaData.brands[0].brand || "Unknown";
      }
    } else {
      browser = navigator.userAgent.includes("Chrome")
        ? "Chrome"
        : navigator.userAgent.includes("Firefox")
        ? "Firefox"
        : navigator.userAgent.includes("Safari") &&
          !navigator.userAgent.includes("Chrome")
        ? "Safari"
        : "Unknown";

      platform = navigator.userAgent.includes("Win")
        ? "Windows"
        : navigator.userAgent.includes("Mac")
        ? "MacOS"
        : navigator.userAgent.includes("Linux")
        ? "Linux"
        : "Unknown";
    }

    return {
      browser,
      platform,
      language: navigator.language,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getGeolocationInfo = () => {
  try {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              accuracy: position.coords.accuracy,
            });
          },
          (error) => {
            console.error("Error getting location:", error);
            reject(error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        reject(new Error("Geolocation not supported."));
      }
    });
  } catch (error) {
    console.error(error);
    return null;
  }
};
