import React, { useEffect, useRef } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const InstagramPost = ({ postUrl = "" }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  }, [postUrl]);

  return (
    <div
      ref={containerRef}
      className="w-full h-full relative flex items-center justify-center mt-2"
    >
      <blockquote
        className="instagram-media"
        data-instgrm-permalink={postUrl}
        data-instgrm-version="14"
        style={{ maxWidth: "100%", height: "100%" }}
      ></blockquote>
      <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 rounded-lg">
        <a
          href={postUrl || "#"}
          rel="noopener noreferrer"
          target="_blank"
          className="bg-white absolute px-4 py-2 rounded-md text-sm font-semibold"
        >
          <div className="flex items-center justify-center">
            <div>Virtual Tour</div>
            <OpenInNewIcon />
          </div>
        </a>
      </div>
    </div>
  );
};

export default InstagramPost;
