import React, { useEffect, useState } from "react";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { message } from "antd";
import { CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#596962",
          },
        },
        input: {
          "&:focus": {
            borderColor: "#596962",
          },
        },
      },
    },
  },
});

const AddPropertyNotes = ({
  handleClose = () => {},
  handleNotesUpdate = () => {},
  openModal = false,
  data = "",
}) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [notes, setNotes] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (data && data?.id) {
      handleGetNotes(data?.id);
    }
  }, [data]);

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Note Saved",
    });
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Failed to save notes!",
    });
  };

  const handleChangeNotes = (e) => {
    setNotes(e?.target?.value || "");
  };

  const closeModal = () => {
    if (saveLoading) return;
    setNotes("");
    handleClose();
  };

  const handleGetNotes = async (id = "") => {
    setDataLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/shared-links/get-shared-unit-notes/${id}`
      )
      .then((response) => response.data)
      .then((result) => {
        console.log(result?.data?.notes, "notes,notes");
        setNotes(result?.data?.notes || "");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  const addNotes = () => {
    if (!data?.id) return;
    setSaveLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/shared-links/update-shared-unit-notes/${data?.id}`,
        { notes: notes?.trim() }
      )
      .then((response) => response.data)
      .then((result) => {
        if (result?.data && result?.success) {
          success();
          handleNotesUpdate(data?.id, result?.data?.notes || "");
          closeModal();
        }
      })
      .catch((err) => {
        console.error(err);
        error();
      })
      .finally(() => setSaveLoading(false));
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="relative">
        <Dialog
          open={openModal}
          fullWidth
          maxWidth="sm"
          className="text-[#596962] relative"
          onClose={closeModal}
        >
          <DialogTitle className="text-[#596962]">Add notes</DialogTitle>
          <IconButton
            aria-label="close"
            disabled={saveLoading}
            onClick={closeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ paddingTop: 0 }} className="text-[#596962]">
            <DialogContentText>
              Leave some notes about the property.
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="notes"
              name="notes"
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={notes}
              onChange={handleChangeNotes}
            />
          </DialogContent>
          <DialogActions>
            <div className="flex items-center justify-end gap-3">
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={closeModal}
                disabled={saveLoading}
              >
                Close
              </Button>
              <Button
                variant="outlined"
                onClick={addNotes}
                color="success"
                size="small"
                disabled={saveLoading}
              >
                {saveLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </DialogActions>

          {/* Loading Overlay */}
          {dataLoading && (
            <div className="absolute inset-0 bg-white bg-opacity-70 flex items-center justify-center">
              <CircularProgress style={{ color: "#596962" }} size={32} />
            </div>
          )}
        </Dialog>
        {contextHolder}
      </div>
    </ThemeProvider>
  );
};

export default AddPropertyNotes;
