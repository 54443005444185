import React, { useRef, useState, useEffect } from "react";
import axios from "axios";

const VideoPlayer = ({ data = "" }) => {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentVideo, setCurrentVideo] = useState({
    video: "",
    thumbnail: "",
  });

  useEffect(() => {
    getVideo();
  }, [data]);

  useEffect(() => {
    if (videoRef && videoRef?.current && currentVideo?.video) {
      videoRef.current.src = currentVideo.video;
      videoRef.current.load();
    }
  }, [currentVideo.video]);

  const togglePlayPause = async () => {
    if (!videoRef?.current) return;

    try {
      if (videoRef.current.paused) {
        await videoRef.current.play();
        setIsPlaying(true);
      } else {
        await videoRef.current.pause();
        setIsPlaying(false);
      }
    } catch (error) {
      console.error("Error playing video:", error);
    }
  };

  const handleSeeking = async () => {
    try {
      if (videoRef && videoRef?.current) {
        if (!isPlaying) {
          setIsPlaying(true);
        }
        setTimeout(async () => {
          if (videoRef?.current) {
            await videoRef.current.play();
          }
        }, 500);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getVideo = async () => {
    try {
      const encodedValue = data?.split("=")[1];
      const testParam = decodeURIComponent(atob(encodedValue));
      await axios
        .post(`${process.env.REACT_APP_API_URL}/playVideo`, {
          folderPath: testParam,
        })
        .then(async (res) => {
          setCurrentVideo((prev) => ({
            ...prev,
            video: res.data?.signedUrl || "",
            thumbnail: res.data?.signedUrlThumb || "",
          }));
        })
        .catch((err) => {
          console.error(err);
          setCurrentVideo((prev) => ({
            ...prev,
            video: "",
            thumbnail: "",
          }));
        });
    } catch (error) {
      console.error("Error getting video file:", error);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry?.isIntersecting && videoRef && videoRef?.current) {
          videoRef.current?.pause();
          setIsPlaying(false);
        }
      },
      { threshold: 0.5 }
    );

    if (containerRef && containerRef?.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef && containerRef?.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="relative h-full w-full flex items-center justify-center"
    >
      <video
        ref={videoRef}
        className="w-full h-full max-h-72"
        controls={isPlaying}
        onSeeking={handleSeeking}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        key={currentVideo?.video}
      >
        <source src={currentVideo?.video} />
        Your browser does not support the video tag.
      </video>
      {!isPlaying && (
        <div
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className="absolute flex flex-col items-center justify-center text-white text-3xl"
        >
          <button
            className="w-16 h-16 bg-black bg-opacity-50 rounded-full transition duration-300 hover:bg-opacity-70"
            onClick={togglePlayPause}
          >
            <svg
              width="64"
              height="64"
              viewBox="0 0 72 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="35.7789"
                cy="35.9322"
                r="34.5428"
                stroke="white"
                strokeWidth="2.4"
              />
              <path
                d="M52.3914 32.8145C54.7914 34.2002 54.7914 37.6643 52.3914 39.0499L30.1723 51.8781C27.7723 53.2638 24.7723 51.5317 24.7723 48.7604L24.7723 23.1041C24.7723 20.3328 27.7723 18.6007 30.1723 19.9864L52.3914 32.8145Z"
                stroke="white"
                strokeWidth="2.4"
              />
            </svg>
          </button>
          <span className="text-sm lg:text:lg mt-2 font-dmserif">
            Virtual Tour Preview
          </span>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
