import { IconButton, Snackbar } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import React, { useState, useEffect } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Image, message } from "antd";
import axios from "axios";
import { createTheme } from "@mui/material/styles";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import Tooltip from "@mui/material/Tooltip";

const TabProperties = ({
  propertyDetails,
  currentRow,
  current,
  currentUnit,
  onData,
  virtual,
  loadSwitch,
  localLink,
  driveData,
  selectedClient = null,
  updateVirtualTourLink = () => {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const [currentVirtualModal, setCurrentVirtualModal] = useState({});
  const [currentId, setCurrentId] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [disabledSwitches, setDisabledSwitches] = useState([]);
  const [isExpanded, setIsExpanded] = useState([]);
  const [modifiedProperty, setModifiedProperty] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [focusedInputIndex, setFocusedInputIndex] = useState(null);
  const [modalId, setModalId] = useState(null);
  const [virtualTourLinks, setVirtualTourLinks] = useState(new Map());

  useEffect(() => {
    getData();
  }, [currentUnit, onData, disabledSwitches, virtual]);

  useEffect(() => {
    generateVirtualTourLinks();
  }, [propertyDetails]);

  const generateVirtualTourLinks = () => {
    try {
      const newLinks = new Map();

      if (Array.isArray(propertyDetails?.virtual_tour)) {
        propertyDetails.virtual_tour?.forEach((x) => {
          if (x?.link) {
            const key = `${x?.name}-${x?.link}`;
            if (!newLinks.has(key)) {
              newLinks.set(key, { name: x?.name, link: x?.link });
            }
          }
        });
      }

      if (Array.isArray(driveData)) {
        driveData.forEach((tour) => {
          const marketName = propertyDetails?.market?.toLowerCase();
          const propertyName = propertyDetails?.name?.toLowerCase();

          if (
            tour?.Key?.includes(marketName) &&
            tour?.Key?.includes(propertyName + "/") &&
            !tour?.Key?.includes("-thumb-")
          ) {
            const generatedUrl =
              tour.Type === "folder"
                ? `${window.location.origin}/folder?folderUrl=${btoa(
                    encodeURIComponent(tour?.original)
                  )}`
                : tour?.Type === "document" || tour?.Type === "image"
                ? `${window.location.origin}/${tour?.Type}?documentUrl=${btoa(
                    encodeURIComponent(tour?.original)
                  )}`
                : `${window.location.origin}/video?videoUrl=${btoa(
                    encodeURIComponent(tour?.original)
                  )}`;

            const tourName = tour?.original?.split("/").pop();
            const key = `${tourName}-${generatedUrl}`;

            if (!newLinks.has(key)) {
              newLinks.set(key, { name: tourName, link: generatedUrl });
            }
          }
        });
      }
      console.log({ newLinks });
      setVirtualTourLinks(Array.from(newLinks.values()));
    } catch (error) {
      console.error("Error generating virtual tour links:", error);
    }
  };

  const toggleExpand = (index) => {
    const newExpandState = [...isExpanded];
    newExpandState[index] = !newExpandState[index];
    setIsExpanded(newExpandState);
  };

  const updateFloorPlanVideo = async (x) => {
    const response = await axios
      .put(`${process.env.REACT_APP_API_URL}/update-unit-virtual`, {
        data: currentVirtualModal,
        link: x?.link || "",
        name: x?.name || "",
      })
      .then((res) => {
        let updateLink = modifiedProperty;

        for (let item of updateLink?.models) {
          if (item?.model_name == currentVirtualModal.model_name) {
            item.floor_plan_video = x?.link || "";
            item.floor_plan_video_name = x?.name || "";
            virtual(item?.model_name, current);
            updateVirtualTourLink(
              currentVirtualModal,
              x?.link || "",
              x?.name || ""
            );
          }
        }
        setModifiedProperty({});
        setModifiedProperty(updateLink);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleBlur = (index, id) => () => {
    setEditMode(!editMode);
    setFocusedInputIndex(null);
    setModalId(null);
  };

  const handleFocus = (index, id) => () => {
    setEditMode(!editMode);
    setFocusedInputIndex(index);
    setModalId(id);
  };

  const getData = () => {
    setModifiedProperty({});
    let data = {
      models: [],
    };
    let virtualTour = "";
    if (propertyDetails?.virtual_tour?.length > 0) {
      for (let i = 0; i < propertyDetails.virtual_tour?.length; i++) {
        virtualTour = virtualTour + propertyDetails.virtual_tour[i].link + " ";
      }
    }
    data.property_name = propertyDetails?.name;
    data.address =
      (propertyDetails?.street_address == "" ||
      propertyDetails?.street_address == null
        ? ""
        : propertyDetails?.street_address + ", ") +
      (propertyDetails?.city == "" || propertyDetails?.city == null
        ? ""
        : propertyDetails?.city + ", ") +
      (propertyDetails?.state == "" || propertyDetails?.state == null
        ? ""
        : propertyDetails?.state + ", ") +
      (propertyDetails?.zip_code == "" || propertyDetails?.zip_code == null
        ? ""
        : propertyDetails?.zip_code);
    data.website = propertyDetails?.website;
    data.google_maps_url = propertyDetails?.google_maps_url;
    data.virtual_tour = virtualTour;
    data.phone = propertyDetails?.phone;
    data.email =
      propertyDetails?.email_1 != null && propertyDetails?.email_1 != ""
        ? propertyDetails?.email_1
        : propertyDetails?.email_2 != null && propertyDetails?.email_2 != ""
        ? propertyDetails?.email_2
        : propertyDetails?.email_3 != null && propertyDetails?.email_3 != ""
        ? propertyDetails?.email_3
        : propertyDetails?.email_4;
    data.year_built = propertyDetails?.year_built?.toString();
    data.year_renovated = propertyDetails?.year_renovated?.toString();
    data.escort = propertyDetails?.escort?.toString();
    data.send = propertyDetails?.send?.toString();
    data.bonus = propertyDetails?.bonus?.toString();
    if (currentUnit?.length > 0) {
      let updatedProperty = currentUnit?.sort((a, b) => {
        if (a.model_name < b.model_name) {
          return -1;
        }
        if (a.model_name > b.model_name) {
          return 1;
        }
        return 0;
      });
      if (current == 0)
        updatedProperty = updatedProperty?.filter((x) => x.unit_studio == true);
      else if (current >= 1 && current <= 3)
        updatedProperty = updatedProperty?.filter(
          (x) => x.unit_bedroom == current
        );
      else if (current == 4)
        updatedProperty = updatedProperty?.filter(
          (x) => x.unit_bedroom >= current
        );
      if (updatedProperty?.length > 0) {
        data.units = updatedProperty[0].units_available;
        data.square_feet = updatedProperty[0].square_feet;

        //data.details = updatedProperty[0].details;
        let model = "";
        while (updatedProperty?.length != 0) {
          var modelDetails = updatedProperty.filter(
            (x) => x.model_name == updatedProperty[0].model_name
          );
          propertyDetails["move_in_special"] = "";
          let propertyDetailsModel = [];
          propertyDetailsModel.push({
            price: "",
            unit: "",
            sqft: "",
            availabilityDate: "",
            availability: "",
            bedRoom: "",
            bathRoom: "",
            move_in_special: "",
            archived: "",
            id: "",
            special_toggle: "",
            special_calculation_price: "PRICE",
            special_calculation_text: "SPECIAL",
          });
          for (let i = 0; i < modelDetails?.length; i++) {
            onData(modelDetails[i]?.move_in_special);
            propertyDetailsModel.push({
              price: modelDetails[i].unit_price,
              rent: modelDetails[i].rent?.split("–")[0]?.trim(),
              unit: modelDetails[i].unit,
              sqft: modelDetails[i].unit_sq_ft,
              availabilityDate: modelDetails[i]?.unit_availability,
              availability: modelDetails[i]?.unit_availability_text,
              bedRoom: modelDetails[i]?.unit_bedroom,
              bathRoom: modelDetails[i]?.unit_bathroom,
              move_in_special: modelDetails[i]?.move_in_special,
              archived: modelDetails[i]?.archived,
              id: modelDetails[i]?.id,
              special_toggle: modelDetails[i]?.special_toggle,
              special_calculation_price:
                modelDetails[i]?.special_calculation_price,
              special_calculation_text:
                modelDetails[i]?.special_calculation_text,
              rent_concession_reference:
                modelDetails[i]?.rent_concession_reference,
              months_fee_reference: modelDetails[i]?.months_fee_reference,
              yard: modelDetails[i]?.yard,
              garage: modelDetails[i]?.garage,
            });
            if (modelDetails[i]?.special_toggle == true) {
              let disable = disabledSwitches;
              disable.push(modelDetails[i]?.id);
              setDisabledSwitches(disable);
            }
          }

          propertyDetails["models"] = [];
          data.models.push({
            floorplan_link: updatedProperty[0].floor_plan,
            model_name: updatedProperty[0].model_name,
            monthly_rent: updatedProperty[0].monthly_rent,
            floor_plan: updatedProperty[0].floor_plan,
            floor_plan_video: updatedProperty[0]?.floor_plan_video,
            floor_plan_video_name: updatedProperty[0]?.floor_plan_video_name,
            property_id: propertyDetails.id,
            propertyDetails: propertyDetailsModel,
            details: updatedProperty[0].unit_details
              .replace("sq ft", "")
              .replace("sq. ft.", ""),
            lease_term: updatedProperty[0].lease_term,
          });
          updatedProperty = updatedProperty.filter(
            (unit) => unit.model_name !== updatedProperty[0].model_name
          );
        }
        data.models.forEach((model) => {
          model.propertyDetails.sort((a, b) => a.price - b.price);
        });
        setModifiedProperty(data);
      }
    } else {
      let updatedProperty = propertyDetails?.units?.sort((a, b) => {
        if (a.model_name < b.model_name) {
          return -1;
        }
        if (a.model_name > b.model_name) {
          return 1;
        }
        return 0;
      });
      if (current == 0)
        updatedProperty = updatedProperty?.filter((x) => x.unit_studio == true);
      else if (current >= 1 && current <= 3)
        updatedProperty = updatedProperty?.filter(
          (x) => x.unit_bedroom == current
        );
      else if (current == 4)
        updatedProperty = updatedProperty?.filter(
          (x) => x.unit_bedroom >= current
        );
      if (updatedProperty?.length > 0) {
        data.units = updatedProperty[0].units_available;
        data.square_feet = updatedProperty[0].square_feet;

        //data.details = updatedProperty[0].details;
        let model = "";
        while (updatedProperty?.length != 0) {
          var modelDetails = updatedProperty.filter(
            (x) => x.model_name == updatedProperty[0].model_name
          );
          propertyDetails["move_in_special"] = "";
          let propertyDetailsModel = [];
          propertyDetailsModel.push({
            price: "",
            unit: "",
            sqft: "",
            availabilityDate: "",
            availability: "",
            bedRoom: "",
            bathRoom: "",
            move_in_special: "",
            archived: "",
            id: "",
            special_toggle: "",
            special_calculation_price: "PRICE",
            special_calculation_text: "SPECIAL",
          });
          for (let i = 0; i < modelDetails?.length; i++) {
            onData(modelDetails[i]?.move_in_special);
            propertyDetailsModel.push({
              price: modelDetails[i].unit_price,
              rent: modelDetails[i].rent?.split("–")[0]?.trim(),
              unit: modelDetails[i].unit,
              sqft: modelDetails[i].unit_sq_ft,
              availabilityDate: modelDetails[i]?.unit_availability,
              availability: modelDetails[i]?.unit_availability_text,
              bedRoom: modelDetails[i]?.unit_bedroom,
              bathRoom: modelDetails[i]?.unit_bathroom,
              move_in_special: modelDetails[i]?.move_in_special,
              archived: modelDetails[i]?.archived,
              id: modelDetails[i]?.id,
              special_toggle: modelDetails[i]?.special_toggle,
              special_calculation_price:
                modelDetails[i]?.special_calculation_price,
              special_calculation_text:
                modelDetails[i]?.special_calculation_text,
              rent_concession_reference:
                modelDetails[i]?.rent_concession_reference,
              months_fee_reference: modelDetails[i]?.months_fee_reference,
              yard: modelDetails[i]?.yard,
              garage: modelDetails[i]?.garage,
            });
            if (modelDetails[i]?.special_toggle == true) {
              let disable = disabledSwitches;
              disable.push(modelDetails[i]?.id);
              setDisabledSwitches(disable);
            }
          }
          propertyDetails["models"] = [];
          data.models.push({
            floorplan_link: updatedProperty[0].floor_plan,
            model_name: updatedProperty[0].model_name,
            monthly_rent: updatedProperty[0].monthly_rent,
            floor_plan: updatedProperty[0].floor_plan,
            floor_plan_video: updatedProperty[0]?.floor_plan_video,
            floor_plan_video_name: updatedProperty[0]?.floor_plan_video_name,
            property_id: propertyDetails.id,
            propertyDetails: propertyDetailsModel,
            details: updatedProperty[0].unit_details
              .replace("sq ft", "")
              .replace("sq. ft.", ""),
            lease_term: updatedProperty[0].lease_term,
          });
          updatedProperty = updatedProperty.filter(
            (unit) => unit.model_name !== updatedProperty[0].model_name
          );
        }
        data.models.forEach((model) => {
          model.propertyDetails.sort((a, b) => a.price - b.price);
        });
        setModifiedProperty(data);
      }
    }
    return () => {
      onData("");
    };
  };

  const handleClick = (event, details) => {
    setCurrentVirtualModal(details);
    setAnchorEl(event.currentTarget);
  };

  const copyUnit = (e, type, model) => {
    handleActionOneUnit(currentRow, e, type, model);
  };

  const handleActionOneUnit = (rowData, unit, type, model) => {
    let units = unit.split("_")[0];
    let sqft = unit.split("_")[1];
    let price = unit.split("_")[2];
    let bedRoom = unit.split("_")[4];
    let bathRoom = unit.split("_")[5];
    let unitAvailabilityText = unit.split("_")[3];
    let move_in_special = unit.split("_")[6];
    let dateNumber = unit.split("_")[7];
    let special_calculation_price = unit.split("_")[7];
    let date = "0";
    let month = "0";
    if (unitAvailabilityText.toLowerCase() == "now") {
      date = new Date().getDate();
      month = parseInt(new Date().getMonth()) + 1;
    } else {
      if (unitAvailabilityText.split(".")?.length == 2) {
        let splitText = unitAvailabilityText.split(".");
        let monthNum = {
          jan: 1,
          feb: 2,
          mar: 3,
          apr: 4,
          may: 5,
          jun: 6,
          jul: 7,
          aug: 8,
          sep: 9,
          sept: 9,
          oct: 10,
          nov: 11,
          dec: 12,
          january: 1,
          february: 2,
          march: 3,
          april: 4,
          june: 6,
          july: 7,
          august: 8,
          september: 9,
          october: 10,
          november: 11,
          december: 12,
        };
        date = splitText[1].trim();
        month = monthNum[splitText[0].toLowerCase().replace(".", "")];
      } else if (unitAvailabilityText.split(",")?.length == 2) {
        let splitText = unitAvailabilityText.split(",");
        let monthNum = {
          jan: "1",
          feb: "2",
          mar: "3",
          apr: "4",
          may: "5",
          jun: "6",
          jul: "7",
          aug: "8",
          sep: "9",
          sept: "9",
          oct: "10",
          nov: "11",
          dec: "12",
          january: "1",
          february: "2",
          march: "3",
          april: "4",
          june: "6",
          july: "7",
          august: "8",
          september: "9",
          october: "10",
          november: "11",
          december: "12",
        };
        date =
          splitText[0]?.split(" ")[1].trim()?.length == 1
            ? splitText[0]?.split(" ")[1].trim()
            : splitText[0]?.split(" ")[1].trim();
        month =
          monthNum[
            splitText[0]?.split(" ")[0].trim().toLowerCase().replace(",", "")
          ];
      } else if (unitAvailabilityText.split(" ")?.length == 2) {
        let splitText = unitAvailabilityText.split(" ");
        let monthNum = {
          jan: 1,
          feb: 2,
          mar: 3,
          apr: 4,
          may: 5,
          jun: 6,
          jul: 7,
          aug: 8,
          sep: 9,
          sept: 9,
          oct: 10,
          nov: 11,
          dec: 12,
          January: 1,
          February: 1,
          March: 2,
          April: 4,
          May: 5,
          June: 6,
          July: 6,
          August: 8,
          September: 9,
          October: 10,
          November: 11,
          December: 12,
          january: 1,
          february: 2,
          march: 3,
          april: 4,
          june: 6,
          july: 7,
          august: 8,
          september: 9,
          october: 10,
          november: 11,
          december: 12,
        };
        date = splitText[1]
          .trim()
          .replace("nd", "")
          .replace("th", "")
          .replace("st", "")
          .replace("rd", "");
        month = monthNum[splitText[0].toLowerCase()];
      }
    }

    let bedRoomBathText =
      bedRoom == 0 ? "Studio" : bedRoom + "bd•" + bathRoom + "ba";
    const emailContent = `
          <div style="font-size: 16px; font-family: 'Calibri', sans-serif;">
            <p style="font-weight: 700; margin: 0; padding: 0"><a href="${
              rowData?.website
            }">${
      rowData?.name
        ? rowData?.name + " *" + model + "* (" + rowData.website + ")"
        : " "
    }</a>${
      (
        ((rowData.send == null || rowData.send == "" || rowData.send == 0) &&
          (rowData.bonus == null ||
            rowData.bonus == "" ||
            rowData.bonus == 0) &&
          rowData.escort > 0) ||
        ((rowData.send == 0 || rowData.send == null || rowData.send == "") &&
          rowData.escort != 0 &&
          rowData.escort != null &&
          rowData.escort != "" &&
          rowData.bonus != 0 &&
          rowData.bonus != null &&
          rowData.bonus != "") ||
        (((rowData.send_type == "percentage" &&
          rowData.escort_type == "percentage") ||
          (rowData.send_type == "percentage" && rowData.escort_type == null) ||
          (rowData.send_type == null && rowData.escort_type == "percentage") ||
          (rowData.send_type == null && rowData.escort_type == null)) &&
          rowData.escort > rowData.send) ||
        (rowData.send_type == "dollar" &&
          rowData.escort_type == "dollar" &&
          rowData.escort > rowData.send)
          ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>"
          : ""
      )
        ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>"
        : ""
    }</p>
            <p style="margin: 0; padding: 0">Price: $${price}<span style="font-weight: 700;"> ${rowData?.special?.replace(
      "special= $",
      special_calculation_price != "null"
        ? "special= $<span style='text-decoration: underline;'>" +
            (special_calculation_price == null ||
            special_calculation_price == ""
              ? ""
              : parseInt(special_calculation_price)) +
            "</span>"
        : "special= $"
    )}</span></p>
            <p style="margin: 0; padding: 0">Size: ${sqft} sqft ${bedRoomBathText}</p>
            <p style="margin: 0; padding: 0">Unit: #${units.replace(
              "#",
              ""
            )}</p>
            <p style="margin: 0; padding: 0">Available: ${
              month + "/" + date
            } - ${rowData?.hold_time ? rowData?.hold_time : ""}</p>
            <p style="margin: 0; padding: 0">Location: ${
              rowData?.submarket ? rowData?.submarket : ""
            }</p>
            <p style="margin: 0; padding: 0">${
              rowData?.last_line ? rowData?.last_line : ""
            }</p>
          </div>
        `;
    const plainTextContent = `${
      rowData?.name
        ? `${rowData.name} *${model}* (${rowData.website}) ${
            (
              ((rowData.send == null ||
                rowData.send == "" ||
                rowData.send == 0) &&
                (rowData.bonus == null ||
                  rowData.bonus == "" ||
                  rowData.bonus == 0) &&
                rowData.escort > 0) ||
              ((rowData.send == 0 ||
                rowData.send == null ||
                rowData.send == "") &&
                rowData.escort != 0 &&
                rowData.escort != null &&
                rowData.escort != "" &&
                rowData.bonus != 0 &&
                rowData.bonus != null &&
                rowData.bonus != "") ||
              (((rowData.send_type == "percentage" &&
                rowData.escort_type == "percentage") ||
                (rowData.send_type == "percentage" &&
                  rowData.escort_type == null) ||
                (rowData.send_type == null &&
                  rowData.escort_type == "percentage") ||
                (rowData.send_type == null && rowData.escort_type == null)) &&
                rowData.escort > rowData.send) ||
              (rowData.send_type == "dollar" &&
                rowData.escort_type == "dollar" &&
                rowData.escort > rowData.send)
                ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>"
                : ""
            )
              ? "*if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit."
              : ""
          }`
        : ""
    }
Price: $${price} ${rowData?.special?.replace(
      "special= $",
      special_calculation_price != "null"
        ? "special= $" +
            (special_calculation_price == null ||
            special_calculation_price == ""
              ? ""
              : parseInt(special_calculation_price))
        : "special= $"
    )}
Size: ${sqft} sqft ${bedRoomBathText}
Unit: #${units.replace("#", "")}
Available: ${month + "/" + date} - ${
      rowData?.hold_time ? rowData?.hold_time : ""
    }
Location: ${rowData?.submarket ? rowData?.submarket : ""}
${rowData?.last_line ? rowData?.last_line : ""}`;

    navigator.clipboard
      .write([
        new ClipboardItem({
          "text/html": new Blob([emailContent], { type: "text/html" }),
          "text/plain": new Blob([plainTextContent], { type: "text/plain" }),
        }),
      ])
      .then(() => {
        console.log("Email info copied to clipboard!");
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.error("Failed to copy email info: ", err);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return; // Prevents the snackbar from closing when the user clicks outside of it
    }
    setSnackbarOpen(false); // Close the Snackbar
  };

  const handleSubmit = async (
    submit,
    convertedTax,
    monthFee,
    rentConcession
  ) => {
    try {
      if (currentId?.id > 0 || submit?.id > 0) {
        let params = {
          special_toggle: false,
          special_calculation_price: null,
          special_calculation_text: null,
          property_id: propertyDetails?.id,
        };

        if (currentId?.id) {
          params.special_toggle = !currentId?.special_toggle;
          params.special_calculation_price = parseFloat(convertedTax);
          params.months_fee_reference = monthFee;
          params.rent_concession_reference = rentConcession;
        }

        if (submit?.id > 0) {
          params.special_toggle = !submit?.special_toggle;
          params.special_calculation_price = parseFloat(convertedTax);
          params.months_fee_reference = monthFee;
          params.rent_concession_reference = rentConcession;
        }
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/unit/${
            currentId?.id > 0 ? currentId?.id : submit?.id
          }`,
          params
        );
        loadSwitch();
        setIsDropdownOpen(false);
        return response?.data;
      }
    } catch (error) {
      console.error("Error updating row:", error);
      throw new Error("Could not update row. Please try again.");
    }
  };

  const handleCancel = (cancel) => {
    loadSwitch();
    setIsDropdownOpen(false);
  };

  const addToLink = (unit_id = "") => {
    if (!unit_id || !selectedClient) return;
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/shared-links/${selectedClient}/units`,
        { unit_id }
      )
      .then((response) => response.data)
      .then((result) => {
        if (result?.data && result?.success) {
          messageApi.success(`${result?.message || "Success!"}`);
        } else if (result?.data && !result?.success) {
          messageApi.info(`${result?.message || "Success!"}`);
        }
      })
      .catch((err) => {
        console.error(err);
        messageApi.error(`${err?.message || "Failed!"}`);
      });
  };

  const isDisabled = (id, e) => disabledSwitches.includes(id);

  const open = Boolean(anchorEl);

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          root: {
            "& .MuiSwitch-thumb": {
              width: 16,
              height: 16,
              display: "flex",
              justifyContent: "center",
            },
            "& .MuiSwitch-track": {
              borderRadius: 16 / 2,
              height: 10,
              width: 32,
              opacity: 1,
              backgroundColor: "rgba(0, 0, 0, 0.25)",
            },
          },
        },
      },
    },
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        position: "relative",
        bottom: "25px",
      }}
    >
      {/* <Modal
          open={isDropdownOpen}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setIsDropdownOpen(false);
              setCurrentId({});
            }
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ProrationCalculator
              isSpecial={true}
              submit={handleSubmit}
              cancel={handleCancel}
            />
          </Box>
        </Modal> */}
      <Snackbar
        open={snackbarOpen}
        style={{ right: 0, top: "65px" }}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseSnackbar}
        message="Copied to clipboard!"
      />
      {modifiedProperty?.models?.length ? (
        <span
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {modifiedProperty?.models?.map((details, i) => (
            <div style={{ marginBottom: "15px", width: "50%" }}>
              <div
                style={{
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                  margin: "10px",
                  height: "100%",
                  borderRadius: "10px",
                }}
              >
                <div style={{ margin: "10px" }}>
                  <div
                    style={{
                      borderBottom: "1px solid #cfcfcf",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "800",
                          color: "#67bba9",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginRight: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "end",
                            }}
                          >
                            {details.model_name}
                          </div>
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                                borderRadius: "50%",

                                marginTop: "17px",
                              }}
                            >
                              {/* <IconButton color="primary">
                                <FavoriteBorderIcon
                                  onClick={() => {
                                    copyUnit(modifiedProperty, "main");
                                  }}
                                />
                              </IconButton> */}
                            </div>
                            <div
                              style={{
                                marginTop: "17px",
                              }}
                            >
                              {/* <a
                                          //href={details.floorplan_link}
                                          href="https://fastly.picsum.photos/id/223/200/300.jpg?hmac=IZftr2PJy4auHpfBpLuMtFhsxgQYlUgXdV5rFwjGItQ"
                                          target="_blank"
                                        >
                                          <img
                                            //src={details.floorplan_link}
                                            src="https://fastly.picsum.photos/id/223/200/300.jpg?hmac=IZftr2PJy4auHpfBpLuMtFhsxgQYlUgXdV5rFwjGItQ"
                                            style={{
                                              height: "40px",
                                              width: "60px",
                                            }}
                                          />
                                        </a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        {details.monthly_rent?.split("-")[0]?.trim()}
                      </div>
                      <div
                        style={{
                          marginBottom: "20px",
                          color: "#adb4c1",
                        }}
                      >
                        {details.details}&nbsp;&nbsp;sqft<sup>2</sup>&nbsp;
                        {details.lease_term}
                      </div>
                    </div>
                    <div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              // disabled={
                              //   !Boolean(propertyDetails?.virtual_tour?.length)
                              // }
                              color="primary"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                handleClick(e, details);
                              }}
                            >
                              <VideoCallIcon />
                            </IconButton>
                            &nbsp;&nbsp;
                            <FavoriteBorderIcon
                              disabled
                              color={
                                (details.floor_plan_video != null &&
                                  details.floor_plan_video != "") ||
                                localLink[current].includes(details.model_name)
                                  ? "primary"
                                  : ""
                              }
                              style={{
                                cursor:
                                  (details.floor_plan_video != null &&
                                    details.floor_plan_video != "") ||
                                  localLink[current].includes(
                                    details.model_name
                                  )
                                    ? "pointer"
                                    : "not-allowed",
                              }}
                              onClick={() => {
                                if (
                                  (details.floor_plan_video != null &&
                                    details.floor_plan_video != "") ||
                                  localLink[current].includes(
                                    details.model_name
                                  )
                                ) {
                                  navigator.clipboard
                                    .writeText(details.floor_plan_video)
                                    .then(() => {
                                      console.log(
                                        "Virtual tour link copied to clipboard:",
                                        details.floor_plan_video != null &&
                                          details.floor_plan_video != ""
                                          ? details.floor_plan_video
                                          : localLink[current].includes(
                                              details.model_name
                                            )
                                      );
                                      setSnackbarOpen(true);
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Failed to copy virtual tour link:",
                                        error
                                      );
                                    });
                                }
                              }}
                            />
                          </div>
                          <div>
                            {propertyDetails?.virtual_tour?.find(
                              (x) =>
                                x?.link === details?.floor_plan_video &&
                                x?.name === details?.floor_plan_video_name
                            ) && (
                              <div key={details?.floor_plan_video}>
                                {
                                  propertyDetails?.virtual_tour?.find(
                                    (x) =>
                                      x?.link === details?.floor_plan_video &&
                                      x?.name === details?.floor_plan_video_name
                                  )?.name
                                }
                              </div>
                            )}

                            {(() => {
                              const tour = driveData
                                ?.filter(
                                  (x) =>
                                    x?.Key?.includes(
                                      propertyDetails?.market?.toLowerCase()
                                    ) &&
                                    x?.Key?.includes(
                                      propertyDetails?.name?.toLowerCase() + "/"
                                    ) &&
                                    !x?.Key?.includes("-thumb-")
                                )
                                ?.find(
                                  (tour) =>
                                    (tour.Type == "folder"
                                      ? window.location.origin +
                                        "/folder?folderUrl=" +
                                        btoa(encodeURIComponent(tour?.original))
                                      : tour.Type == "document" ||
                                        tour.Type == "image"
                                      ? window.location.origin +
                                        "/" +
                                        tour.Type +
                                        "?documentUrl=" +
                                        btoa(encodeURIComponent(tour?.original))
                                      : window.location.origin +
                                        "/video?videoUrl=" +
                                        btoa(
                                          encodeURIComponent(tour?.original)
                                        )) == details.floor_plan_video
                                );

                              return tour ? (
                                <div>
                                  {
                                    tour?.original?.split("/")[
                                      tour?.original?.split("/").length - 1
                                    ]
                                  }
                                </div>
                              ) : null;
                            })()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ padding: "10px", display: "flex" }}>
                      {/* <img
                          src={details?.floor_plan}
                          width={"90"}
                          alt={"Floor plan image"}
                        /> */}
                      <Image
                        height={70}
                        src={details.floor_plan}
                        placeholder={
                          <Image preview={false} src={details.floor_plan} />
                        }
                        preview={{
                          mask: <div>Preview</div>,
                          zIndex: 2000,
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div key={i}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "15px",
                          fontWeight: "900",
                        }}
                      >
                        <button
                          onClick={() => toggleExpand(i)}
                          style={{ color: "#4e5c76" }}
                        >
                          <span>
                            {isExpanded[i]
                              ? `SHOW ${
                                  details?.propertyDetails?.length - 1
                                } PRICES`
                              : `HIDE ${
                                  details?.propertyDetails?.length - 1
                                } PRICES`}
                          </span>
                          {isExpanded[i] ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </button>
                      </div>
                      {!isExpanded[i] && (
                        <div>
                          {details?.propertyDetails?.map((amenties, index) => (
                            <div
                              style={{
                                borderBottom: "1px solid #cfcfcf",
                                color:
                                  amenties?.archived == true ? "#adb4c1" : "",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "8%",
                                    textAlign: "flex-start",
                                  }}
                                >
                                  {amenties?.price != 0
                                    ? amenties?.price?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                      })
                                    : amenties?.rent?.split("-")[0]}
                                </div>
                                <div
                                  style={{
                                    width: "3%",
                                    textAlign: "flex-start",
                                  }}
                                >
                                  {amenties?.special_calculation_price ==
                                  "PRICE" ? null : (
                                    <>-</>
                                  )}
                                </div>
                                <div
                                  style={{
                                    width: "8%",
                                    textAlign: "flex-start",
                                  }}
                                >
                                  {amenties.unit}
                                </div>
                                <div
                                  style={{
                                    width: "3%",
                                    textAlign: "flex-start",
                                  }}
                                >
                                  {amenties?.special_calculation_price ==
                                  "PRICE" ? null : (
                                    <>-</>
                                  )}
                                </div>
                                <div
                                  style={{
                                    width: "11%",
                                    textAlign: "flex-start",
                                  }}
                                >
                                  {amenties?.special_calculation_price ==
                                  "PRICE" ? null : (
                                    <>
                                      {amenties.sqft}&nbsp;&nbsp;sqft
                                      <sup>2</sup>
                                    </>
                                  )}
                                </div>
                                <div
                                  style={{
                                    width: "3%",
                                    textAlign: "flex-start",
                                  }}
                                >
                                  {amenties?.special_calculation_price ==
                                  "PRICE" ? null : (
                                    <>-</>
                                  )}
                                </div>
                                <div
                                  style={{
                                    width: "12%",
                                    textAlign: "flex-start",
                                  }}
                                >
                                  {amenties?.special_calculation_price ==
                                  "PRICE" ? (
                                    <span
                                      style={{
                                        color: "#4e5c76",
                                        fontWeight: 800,
                                        fontSize: "15px",
                                      }}
                                    >
                                      <span style={{ fontWeight: 900 }}>
                                        {amenties.special_calculation_price}
                                      </span>
                                    </span>
                                  ) : (
                                    <>
                                      <span style={{ fontWeight: 900 }}>
                                        {amenties.special_calculation_price !=
                                          null &&
                                        amenties.special_calculation_price != ""
                                          ? parseInt(
                                              amenties.special_calculation_price
                                            )
                                          : ""}
                                      </span>
                                    </>
                                  )}
                                </div>
                                <div
                                  style={{
                                    width: "35%",
                                    textAlign: "flex-start",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {amenties?.special_calculation_price ==
                                  "PRICE" ? (
                                    <span
                                      style={{
                                        color: "#4e5c76",
                                        fontWeight: 800,
                                        fontSize: "15px",
                                      }}
                                    >
                                      {amenties.special_calculation_text}
                                    </span>
                                  ) : (
                                    <>
                                      {editMode &&
                                        focusedInputIndex === index &&
                                        modalId == amenties.id && (
                                          <span>
                                            <input
                                              type="text"
                                              value={
                                                amenties.special_calculation_text
                                              }
                                              onBlur={handleBlur(
                                                index,
                                                amenties.id
                                              )}
                                              placeholder={`Input ${index + 1}`}
                                              style={{
                                                border: "2px solid #1976d2",
                                                borderRadius: "5px",
                                                width: "100%",
                                              }}
                                            />
                                          </span>
                                        )}
                                      {modalId != amenties.id && (
                                        <span
                                          onClick={handleFocus(
                                            index,
                                            amenties.id
                                          )}
                                        >
                                          {amenties.special_calculation_text}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div
                                  style={{
                                    width: "6%",
                                    textAlign: "flex-start",
                                  }}
                                >
                                  {amenties.availability}
                                </div>
                                <div
                                  style={{
                                    width: "2%",
                                    textAlign: "flex-start",
                                    color: "green",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    fontSize: "18px",
                                  }}
                                >
                                  {amenties.yard ? "Y" : ""}
                                </div>
                                <div
                                  style={{
                                    width: "2%",
                                    textAlign: "flex-start",
                                    color: "Blue",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    fontSize: "18px",
                                  }}
                                >
                                  {amenties.garage ? "G" : ""}
                                </div>
                                <div
                                  style={{
                                    // width: "7%",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  {amenties?.special_calculation_price ==
                                  "PRICE" ? null : (
                                    <IconButton color="primary">
                                      <FavoriteBorderIcon
                                        onClick={() => {
                                          copyUnit(
                                            amenties.unit +
                                              "_" +
                                              amenties.sqft +
                                              "_" +
                                              amenties.price +
                                              "_" +
                                              amenties.availability +
                                              "_" +
                                              amenties.bedRoom +
                                              "_" +
                                              amenties.bathRoom +
                                              "_" +
                                              amenties.move_in_special +
                                              "_" +
                                              (amenties.special_calculation_price !=
                                                null &&
                                              amenties.special_calculation_price !=
                                                ""
                                                ? parseInt(
                                                    amenties.special_calculation_price
                                                  )
                                                : ""),
                                            "sub",
                                            details.model_name
                                          );
                                        }}
                                      />
                                    </IconButton>
                                  )}
                                  {amenties?.special_calculation_price ==
                                  "PRICE" ? null : selectedClient ? (
                                    <Tooltip title="Add to client's shared link">
                                      <IconButton
                                        onClick={() => addToLink(amenties?.id)}
                                        color="primary"
                                      >
                                        <DomainAddIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </span>
      ) : (
        <div style={{ marginLeft: "10px" }}>No Record Found</div>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {virtualTourLinks && virtualTourLinks?.length > 0
          ? virtualTourLinks?.map((item) => (
              <MenuItem
                key={item?.link}
                className="line-clamp-1"
                style={{
                  color:
                    currentVirtualModal?.floor_plan_video === item?.link &&
                    currentVirtualModal?.floor_plan_video_name === item?.name
                      ? "#1976D2"
                      : "",
                }}
                onClick={() => {
                  setAnchorEl(null);
                  updateFloorPlanVideo({ link: item?.link, name: item?.name });
                }}
              >
                {item?.name}
              </MenuItem>
            ))
          : null}
      </Menu>

      {contextHolder}
    </div>
  );
};
export default TabProperties;
