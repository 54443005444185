import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import useStore from "../../store";
import SidekickAccordian from "./SidekickAccrodian";
import SharedPropertyCard from "./SharedPropertyCard";
import SharedPropertyCardMobile from "./SharedPropertyCardMobile";
import SharedUnitsFooter from "./SharedUnitsFooter";
import AddPropertyNotes from "./AddPropertyNotes";
import {
  getDeviceInfo,
  getGeolocationInfo,
} from "../../utils/useragentinfo.utils";

const SharedUnits = () => {
  const [searchParams] = useSearchParams();
  const encodedClientId = searchParams.get("client");

  const [messageApi, contextHolder] = message.useMessage();

  const user = useStore((state) => state.user);
  const [dataList, setDataList] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [openModal, setOpenModal] = useState({ data: null, open: false });
  const [hidden, setHidden] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const [lastUpdated, setLastUpdated] = useState("");
  const [dataLoading, setDataLoading] = useState(false);


  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 1024);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    trackVisit();
  }, []);

  useEffect(() => {
    getUnitsForClient();
  }, [hidden]);

  useEffect(() => {
    if (dataList && dataList?.length && dataList[0]?.created_at) {
      const utcDate = new Date(dataList[0]?.created_at || "");
      const options = { year: "numeric", month: "long", day: "numeric" };
      const localDate = utcDate.toLocaleDateString(undefined, options);
      setLastUpdated(localDate || "");
    }
  }, [dataList]);

  const parseAvailabilityDate = (unitAvailabilityText) => {
    try {
      let date = "0";
      let month = "0";

      if (!unitAvailabilityText) return { date, month };

      const monthNum = {
        jan: 1,
        feb: 2,
        mar: 3,
        apr: 4,
        may: 5,
        jun: 6,
        jul: 7,
        aug: 8,
        sep: 9,
        sept: 9,
        oct: 10,
        nov: 11,
        dec: 12,
        january: 1,
        february: 2,
        march: 3,
        april: 4,
        may: 5,
        june: 6,
        july: 7,
        august: 8,
        september: 9,
        october: 10,
        november: 11,
        december: 12,
      };

      if (unitAvailabilityText.toLowerCase() === "now") {
        const today = new Date();
        return { date: today.getDate(), month: today.getMonth() + 1 };
      }

      const cleanedText = unitAvailabilityText
        .toLowerCase()
        .replace(/,/g, "")
        .trim();
      const splitText = cleanedText.split(/\.|\s+/);

      if (splitText?.length === 2) {
        const [monthText, dateText] = splitText;
        date = dateText.replace(/\D/g, "");
        month = monthNum[monthText];
      }

      return { date, month };
    } catch (error) {
      console.error(error);
      return {
        date: "",
        month: "",
      };
    }
  };

  const error = () => {
    messageApi.open({
      type: "error",
      content: "Operation Failed!",
    });
  };

  const handleNotesModalClose = () => {
    setOpenModal({
      open: false,
      data: null,
    });
  };

  const handleNotesModalOpen = (unitData = null) => {
    setOpenModal({
      open: true,
      data: unitData,
    });
  };

  const handleNotesUpdate = (id = "", notes = "") => {
    if (!id) return;

    setDataList((prev) =>
      prev.map((item) => (item?.id === id ? { ...item, notes } : item))
    );
  };

  const trackVisit = async () => {
    if (!encodedClientId) return;
    const decodedClientId = decodeURIComponent(atob(encodedClientId));
    if (!decodedClientId) return;

    const lastVisitData = JSON.parse(
      localStorage.getItem("shared-units-last-visit")
    );
    const currentTimeUTC = new Date().toISOString();

    let locationInfo = null;
    let deviceInfo = null;

    try {
      deviceInfo = await getDeviceInfo();
      locationInfo = await getGeolocationInfo();
    } catch (error) {
      console.warn("Unable to retrieve geolocation.");
    }

    const visitData = {
      clientId: decodedClientId,
      route: "/shared-units",
      timeStamp: currentTimeUTC,
      deviceInfo,
      locationInfo,
    };

    if (
      !lastVisitData ||
      !lastVisitData?.timeStamp ||
      new Date(currentTimeUTC).getTime() -
        new Date(lastVisitData?.timeStamp).getTime() >
        15 * 60 * 1000
    ) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/shared-links/track-visit`,
          { visitData }
        );

        localStorage.setItem(
          "shared-units-last-visit",
          JSON.stringify(visitData)
        );
      } catch (error) {
        console.error("Error tracking visit:", error);
      }
    }
  };

  const getUnitsForClient = async () => {
    if (!encodedClientId) return;
    const decodedClientId = decodeURIComponent(atob(encodedClientId));
    if (!decodedClientId) return;
    setDataLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/shared-links/get-shared-units-by-client/${decodedClientId}`,
        {
          params: { hidden: hidden },
        }
      )
      .then((response) => response.data)
      .then((result) => {
        if (result?.data && result?.data?.length) {
          setDataList(result?.data);
        } else {
          setDataList([]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  const updateFavorite = async (id = "") => {
    if (!id || !dataList || !dataList?.length) return;

    const data = dataList?.find((x) => x?.id === id);
    const isFavorite = Boolean(!data?.favorite);

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/shared-links/update-shared-unit-favorite/${id}`,
        { favorite: isFavorite }
      )
      .then((response) => response.data)
      .then((result) => {
        if (result?.data && result?.success) {
          setDataList((prev) =>
            prev.map((item) =>
              item?.id === id ? { ...item, favorite: isFavorite } : item
            )
          );
          if (isFavorite) {
            messageApi.open({
              type: "success",
              content: "Added to favourites!",
            });
          } else {
            messageApi.open({
              type: "success",
              content: "Removed from favourites!",
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        error();
      });
  };

  const hideUnits = async (id = "") => {
    if (!id || !dataList || !dataList?.length) return;

    const data = dataList?.find((x) => x?.id === id);
    const isHidden = Boolean(!data?.hidden);

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/shared-links/update-shared-unit-hidden/${id}`,
        { hidden: isHidden }
      )
      .then((response) => response.data)
      .then((result) => {
        if (result?.data && result?.success) {
          setDataList((prev) => prev.filter((item) => item?.id !== id));
          if (isHidden) {
            messageApi.open({
              type: "success",
              content: "Property moved to hidden page!",
            });
          } else {
            messageApi.open({
              type: "success",
              content: "Property moved to main page!",
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
        error();
      });
  };

  const accordianContent = [
    {
      idx: 0,
      title: "First time working with a locator?",
      content: (
        <div className="text-justify p-2 lg:p-4">
          I pride myself on finding my clients the perfect apartment for free
          but I do ask that you stick with just one locator during your search.
          Only one of us gets credit for the work, and I put a lot of care and
          effort into helping you find the right place.
          <br />
          <br />
          All I ask is that my time and expertise are respected so I can be
          compensated for the work I love doing. I truly appreciate you valuing
          my time and I can’t wait to help you find your next home!
          <br />
          <br />
          If you come across a property on your own that you’re interested in,
          let me know! I have close relationships with a lot of properties and
          can see what additional specials I can get you.
          <br />
          <br />
          Also, if you see any posts from locators on TikTok or IG that you
          like, send me the posts and I can tell you which property they posted.
        </div>
      ),
    },
    {
      idx: 1,
      title: "How do specials work and what does pro-rating mean?",
      content: (
        <div className="p-2 lg:p-4">
          <div className="text-justify">
            When an apartment offers a special, like 1 month free, your monthly
            rent for the entire lease is based on the price after that special
            is applied. So instead of getting one month totally free and paying
            full price the rest of the lease, it’s like the free month is turned
            into a credit that lowers your rent each month.
          </div>

          <br />
          <br />
          <div className="">
            For example:
            <br />
            <br />
            •Rent is $1,500/month
            <br />
            •You get 1 month free on a 12-month lease
            <br />
            •That’s $1,500 of savings spread over 12 months
            <br />
            <br />
            So your effective monthly rent =
            <br />
            <span className="font-semibold">
              $1,500 × 11 months ÷ 12 = $1,375/month
            </span>
          </div>
        </div>
      ),
    },
    {
      idx: 2,
      title: "Are you interested in a free 2 hour move or cash rebate?",
      content: (
        <div className="text-justify p-2 lg:p-4">
          As a little thank-you, I offer all my clients a free 2-hour move.
          You’d just cover anything over the 2 hours and any fees. If you don’t
          need movers, no worries! I can give you $150 cash back after you move
          in to help with applications, boxes, tacos… whatever you need. 😊
        </div>
      ),
    },
  ];

  return (
    <div className="w-screen h-screen overflow-y-auto bg-white">
      {/*Header Section*/}
      <div className="w-full lg:flex bg-[#EBE6DE] rounded-b-[60px] lg:rounded-b-[100px] flex-grow">
        {/*Left Section*/}
        <div className="flex-1 flex flex-col items-center">
          <div className="flex flex-col xl:gap-28 p-8 lg:p-24">
            <div>
              <div className="flex flex-col gap-2 text-[#596962]">
                <p className="font-playfair text-lg lg:text-xl">Created for</p>
                <h1 className="font-playfair text-3xl lg:text-5xl mb-[10px] lg:mb-[15px]">
                  {dataList?.length && dataList[0]?.client?.firstname
                    ? `${dataList[0]?.client?.firstname} ${dataList[0]?.client?.lastname}`
                    : ""}
                </h1>
                <p className="font-inter text-[12px] lg:text-[14px]">
                  {lastUpdated}
                </p>
              </div>
              <div className="my-[25px] lg:my-[45px] flex flex-col gap-[8px] lg:gap-[20px]">
                {[...accordianContent]?.map((x, index) => (
                  <SidekickAccordian
                    key={index}
                    index={index}
                    openIndex={openIndex}
                    setOpenIndex={setOpenIndex}
                    content={x?.content || ""}
                    title={x?.title || ""}
                  />
                ))}
              </div>
            </div>

            <div>
              <span
                onClick={() => setHidden((prev) => !prev)}
                className="text-[12px] lg:text-[16px] text-[#596962] underline cursor-pointer"
              >
                {hidden ? "Back to main page" : "Hidden properties"}
              </span>
            </div>
          </div>
        </div>

        {/*Right Section*/}
        <div className="lg:flex-1 flex items-center justify-around text-[#596962] pt-6">
          <div className="w-[85%] p-2 px-6 pb-12 md:p-12 lg:p-14 xl:p-16 relative">
            <div className="relative w-full h-full flex flex-col">
              <div className="absolute top-3 left-3 w-full h-full  rounded-2xl border border-gray-400 aspect-square"></div>
              <div className="relative w-full h-full bg-white rounded-2xl shadow-lg p-6 z-10 aspect-square"></div>
            </div>
            <div className="flex flex-col lg:flex-row lg:justify-between items-center font-playfair">
              <div className="my-6 text-center lg:text-left">
                <div className="text-2xl lg:text-3xl">
                  AUBREE RACCA REALTOR®
                </div>
                <div className="flex flex-col lg:flex-row text-lg">
                  <div>
                    aubree@aubreetheagent.com
                    <span className="hidden lg:inline-block px-2">|</span>
                    <span className="block lg:inline">512.988.3991</span>
                  </div>
                </div>
                <div> Agent with Apartment Hunters</div>
              </div>
              <div className="flex items-end justify-end gap-2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    dataList &&
                    dataList?.length &&
                    dataList[0]?.units?.property?.market === "DFW"
                      ? "https://www.instagram.com/aubreetheagentdfw/"
                      : " https://www.instagram.com/aubreetheagent?"
                  }
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 62 62"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_244_2752)">
                      <path
                        d="M26.974 18.1693C30.092 18.0392 36.4409 17.8007 39.1816 18.9976C42.8808 20.6152 43.0022 23.7983 43.1453 27.3283C43.2624 30.2165 43.3535 33.4343 43.0672 36.3095C42.5642 41.3964 39.5242 43.0486 34.7582 43.2091C32.1432 43.2958 29.1683 43.3002 26.5533 43.2091C21.757 43.04 18.8124 41.2012 18.3224 36.149C18.0231 33.07 18.0362 29.154 18.2443 26.062C18.6433 20.0905 21.2236 18.4078 26.9783 18.165L26.974 18.1693ZM25.712 20.9274C21.2453 21.218 20.894 23.3776 20.7596 27.324C20.6728 29.8566 20.6425 32.9399 20.842 35.4465C21.2019 39.9523 23.4439 40.1821 27.4206 40.2992C29.5369 40.3599 31.8527 40.3599 33.969 40.2992C37.126 40.2081 39.9882 40.1127 40.4696 36.3138C40.8339 33.43 40.6951 29.6181 40.552 26.6995C40.3525 22.7315 39.754 21.1139 35.3914 20.9014C32.4772 20.7583 28.6176 20.741 25.7163 20.9274H25.712Z"
                        fill="#596962"
                      />
                      <path
                        d="M30.2048 24.7144C34.5588 24.3718 37.7419 28.6955 36.4886 32.8109C34.9318 37.9065 28.0322 38.7304 25.3738 34.0729C23.1188 30.1266 25.647 25.0744 30.2048 24.7144ZM29.9663 27.3901C26.1891 27.9019 26.1284 34.1553 30.3479 34.5326C35.3698 34.9793 35.5389 26.6356 29.9663 27.3901Z"
                        fill="#596962"
                      />
                      <path
                        d="M36.9786 23.3696C38.9691 23.0054 39.2944 25.8892 37.6508 26.1711C35.5475 26.5311 35.3654 23.6689 36.9786 23.3696Z"
                        fill="#596962"
                      />
                      <path
                        d="M30.6731 60.2637C47.0146 60.2637 60.262 47.0163 60.262 30.6748C60.262 14.3333 47.0146 1.08594 30.6731 1.08594C14.3316 1.08594 1.08423 14.3333 1.08423 30.6748C1.08423 47.0163 14.3316 60.2637 30.6731 60.2637Z"
                        stroke="#596962"
                        stroke-width="2.16832"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_244_2752">
                        <rect width="61.3461" height="61.3461" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    dataList &&
                    dataList?.length &&
                    dataList[0]?.units?.property?.market === "DFW"
                      ? "https://www.tiktok.com/@aubreetheagentdfw?_t=8pYlBzSaOUx&_r=1"
                      : "https://www.tiktok.com/@aubreetheagent?_t=8pYmHnYLUT8&_r=1"
                  }
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 62 62"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_244_2759)">
                      <path
                        d="M35.614 18.5918C35.5619 21.7272 38.242 24.4116 41.3817 24.3595V28.4923C39.9116 28.5227 38.4501 28.1628 37.1275 27.5426L35.6183 26.6796V35.6521C35.1933 41.1683 29.2304 44.4902 24.304 41.8188C19.1261 39.013 19.0003 31.528 24.0742 28.5357C25.579 27.6467 27.2789 27.3171 29.0179 27.5123V31.7535C26.9537 31.1073 24.7247 32.5167 24.4862 34.6721C24.1436 37.7554 27.6259 39.7373 30.1021 37.8595C30.7049 37.4041 31.4465 36.3156 31.4465 35.5437V18.5918H35.6183H35.614Z"
                        fill="#596962"
                      />
                      <path
                        d="M30.8606 60.2637C47.2021 60.2637 60.4495 47.0163 60.4495 30.6748C60.4495 14.3333 47.2021 1.08594 30.8606 1.08594C14.5191 1.08594 1.27173 14.3333 1.27173 30.6748C1.27173 47.0163 14.5191 60.2637 30.8606 60.2637Z"
                        stroke="#596962"
                        stroke-width="2.16832"
                        stroke-miterlimit="10"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_244_2759">
                        <rect
                          width="61.3461"
                          height="61.3461"
                          fill="white"
                          transform="translate(0.1875)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*End of Header Section*/}

      <div className="my-20 lg:my-24 text-[#596962]">
        <h2 className="text-center font-playfair text-4xl lg:text-5xl">
          {dataList?.length && dataList[0]?.client?.firstname && !hidden
            ? `${dataList[0]?.client?.firstname}'s Curated Listings`
            : dataList?.length && dataList[0]?.client?.firstname && hidden
            ? "Hidden Properties"
            : ""}
        </h2>
      </div>
      <div className="my-4 flex flex-col justify-center items-center px-6 lg:px-24 gap-14 lg:gap-0">
        {dataLoading ? (
          <p>Loading...</p>
        ) : dataList?.length > 0 ? (
          dataList.map((item) =>
            isDesktop ? (
              <SharedPropertyCard
                key={item?.id}
                itemId={item?.id}
                data={item}
                parseAvailabilityDate={parseAvailabilityDate}
                handleNotesModalOpen={handleNotesModalOpen}
                updateFavorite={updateFavorite}
                hideUnits={hideUnits}
              />
            ) : (
              <SharedPropertyCardMobile
                key={item?.id}
                itemId={item?.id}
                data={item}
                parseAvailabilityDate={parseAvailabilityDate}
                handleNotesModalOpen={handleNotesModalOpen}
                updateFavorite={updateFavorite}
                hideUnits={hideUnits}
              />
            )
          )
        ) : (
          <p>No data available</p>
        )}
      </div>

      <div className="mt-20">
        <SharedUnitsFooter />
      </div>

      <AddPropertyNotes
        openModal={openModal?.open}
        handleClose={handleNotesModalClose}
        data={openModal?.data}
        handleNotesUpdate={handleNotesUpdate}
      />
      {contextHolder}
    </div>
  );
};

export default SharedUnits;
