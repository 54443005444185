import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
} from "@mui/material";

import { message } from "antd";

let updateCancelTokenSource = null;

const bedrooms = ["Studio", "One", "Two", "Three", "Four +"];
const initialState = {
  client: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    moveInDate: "",
    budget: 0,
    bedrooms: [],
  },
};

const EditClientInfo = ({
  clientId = "",
  open = false,
  setEditModal = () => {},
  updateClientInfo = () => {},
}) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [saveLoading, setSaveLoading] = useState(false);
  const [clientDetails, setClientDetails] = useState({
    ...initialState.client,
  });

  useEffect(() => {
    if (clientId && open) {
      getClientData();
    }
  }, [clientId, open]);

  const handleCloseDialog = () => {
    setClientDetails({ ...initialState.client });
    setEditModal({ show: false, clientId: "" });
  };

  const successMsg = (msg) => {
    messageApi.open({
      type: "success",
      content: msg && msg?.length ? msg : "Success!",
    });
  };

  const errorMsg = (msg) => {
    messageApi.open({
      type: "error",
      content: msg && msg?.length ? msg : "Failed",
    });
  };

  const handleClientChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formValue = value;
    if (name === "firstName" || name === "lastName") {
      formValue = value.trim().replace(/^0+/, "");
    }
    if (name === "budget") {
      formValue = Number(value);
    }
    setClientDetails((prev) => ({
      ...prev,
      [name]: formValue,
    }));
  };


  const getClientData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-client-details/${clientId}`
      );
      const client = response?.data?.data || {};
      const data = {
        firstName: client?.firstname || "",
        lastName: client?.lastname || "",
        phone: client?.phone || "",
        email: client?.email || "",
        moveInDate: client?.move_in_date || "",
        budget: client?.budget || "",
        bedrooms: client?.no_of_bedrooms || [],
      };
      setClientDetails(data);
    } catch (error) {
      setClientDetails({ ...initialState.client });
      console.error(error?.message);
    }
  };

  const updateClientDetails = async () => {
    try {
      if (
        !clientDetails?.firstName ||
        !clientDetails?.email ||
        !clientDetails?.phone
      ) {
        errorMsg("Required fields missing!");
        return console.log("Required fields missing!");
      }
      setSaveLoading(true);
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}/update-client-details/${clientId}`,
        clientDetails
      );
      if (result?.data?.success && result?.data?.data) {
        successMsg(result?.data?.message);

        const client = result?.data?.data || {}

        const obj = {
          client_id: client?.id || "",
          firstname: client?.firstname || "",
          lastname: client?.lastname || "",
          email: client?.email || "",
          phone: client?.phone || "",
          move_in_date: client?.move_in_date || "",
          no_of_bedrooms: client?.no_of_bedrooms || [],
          budget: client?.budget || "",
        };
        updateClientInfo(obj);
      } else {
        errorMsg(result?.data?.message);
      }
      setSaveLoading(false);
      handleCloseDialog();
    } catch (error) {
      setSaveLoading(false);
      console.error(`Error at updateClientDetails :: ${error}`);
      errorMsg("Failed to update user info!");
    }
  };

  return (
    <>
      {contextHolder}
      <Dialog
        open={open}
        onClose={() => {
          if (saveLoading) return;
          handleCloseDialog();
        }}
        maxWidth="sm"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 3,
            padding: "8px 8px",
            width: "400px",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontWeight: "bold",
            fontSize: "1rem",
            paddingBottom: "8px",
            color: "#333",
          }}
        >
          Edit Client Info
        </DialogTitle>

        <DialogContent
          dividers
          sx={{
            padding: "8px 8px",
          }}
        >
          <TextField
            label="First Name"
            fullWidth
            margin="normal"
            size="small"
            name="firstName"
            required
            value={clientDetails?.firstName || ""}
            onChange={handleClientChange}
          />
          <TextField
            label="Last Name"
            fullWidth
            size="small"
            value={clientDetails?.lastName || ""}
            margin="normal"
            name="lastName"
            // required
            onChange={handleClientChange}
          />
          <TextField
            label="Phone"
            fullWidth
            size="small"
            value={clientDetails?.phone || ""}
            margin="normal"
            required
            name="phone"
            onChange={handleClientChange}
          />
          <TextField
            label="Email"
            type="email"
            name="email"
            size="small"
            required
            fullWidth
            value={clientDetails?.email || ""}
            margin="normal"
            onChange={handleClientChange}
          />

          <TextField
            label="Move in Date"
            type="date"
            name="moveInDate"
            fullWidth
            size="small"
            margin="normal"
            value={
              clientDetails?.moveInDate
                ? new Date(clientDetails.moveInDate).toISOString().split("T")[0]
                : ""
            }
            onChange={handleClientChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl variant="outlined" fullWidth sx={{ marginTop: 2 }}>
            <InputLabel>Number of Bedrooms</InputLabel>
            <Select
              name="bedrooms"
              fullWidth
              size="small"
              inputProps={{ "aria-label": "Number of Bedrooms" }}
              value={
                Array.isArray(clientDetails?.bedrooms)
                  ? clientDetails?.bedrooms
                  : []
              }
              label={"Number of Bedrooms"}
              multiple
              onChange={handleClientChange}
            >
              {bedrooms.map((bedroom, index) => (
                <MenuItem key={index} value={bedroom}>
                  {bedroom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Budget"
            type="number"
            size="small"
            name="budget"
            fullWidth
            value={clientDetails?.budget}
            margin="normal"
            onChange={handleClientChange}
          />
        </DialogContent>

        <DialogActions
          sx={{
            padding: "8px 8px",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={updateClientDetails}
            variant="contained"
            color="primary"
            size="small"
            disabled={saveLoading}
            sx={{
              textTransform: "none",
              backgroundColor: "#1976D2",
              "&:hover": {
                backgroundColor: "#1565C0",
              },
            }}
          >
            {saveLoading ? "Saving..." : "Save"}
          </Button>
          <Button
            size="small"
            onClick={handleCloseDialog}
            variant="outlined"
            disabled={saveLoading}
            sx={{
              textTransform: "none",
              ml: 1,
              "&:hover": {
                backgroundColor: "#F5F5F5",
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditClientInfo;
