import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Restore as RestoreIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";

import { Empty, message } from "antd";

let resetCancelTokenSource = null;
let resetClientUnitcancelTokenSource = null;
let updateCancelTokenSource = null;
let deleteCancelTokenSource = null;
let hideLoadingMessage = null;

const EditSharedUnits = ({ clientId = "" }) => {
  const [dataList, setDataList] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [editingUnit, setEditingUnit] = useState(null);
  const [updatedTexts, setUpdatedTexts] = useState({});
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    if (clientId) {
      getSharedUnitsForClient();
    }
  }, [clientId]);

  const handleCloseDialog = () => {
    setOpenEditDialog(false);
    setEditingUnit(null);
  };

  const handleChange = (field, value) => {
    setUpdatedTexts((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const showLoadingMessage = (msg = "Loading...") => {
    if (!hideLoadingMessage) {
      hideLoadingMessage = message.loading(msg, 0);
    }
  };

  const clearLoadingMessage = () => {
    if (hideLoadingMessage) {
      hideLoadingMessage();
      hideLoadingMessage = null;
    }
  };

  const handleEditClick = (unit) => {
    setEditingUnit(unit);
    setUpdatedTexts({
      title_text: unit?.title_text || "",
      sub_market_bd_ba_sqft_text: unit?.sub_market_bd_ba_sqft_text || "",
      price_text: unit?.price_text || "",
      special_price_text: unit?.special_price_text || "",
      special_calc_text: unit?.special_calc_text || "",
      unit_model_text: unit?.unit_model_text || "",
      availability_holdtime_text: unit?.availability_holdtime_text || "",
    });
    setOpenEditDialog(true);
  };

  const handleRestoreClientUnitsText = async () => {
    try {
      if (resetClientUnitcancelTokenSource) {
        resetClientUnitcancelTokenSource.cancel(
          "Request canceled due to new request."
        );
      }
      resetClientUnitcancelTokenSource = axios.CancelToken.source();
      showLoadingMessage("Restoring client's unit texts...");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/shared-links/reset-client-units-text/${clientId}`,
        {},
        {
          cancelToken: resetClientUnitcancelTokenSource.token,
        }
      );

      if (response?.data?.success) {
        message.success("Client's all unit texts restored!");
        // getSharedUnitsForClient();
        const updatedList = response?.data?.data || [];
        setDataList((prevDataList) =>
          prevDataList.map((unit) => {
            const updatedUnit = updatedList.find((u) => u?.id === unit?.id);
            return updatedUnit ? { ...unit, ...updatedUnit } : unit;
          })
        );
      } else {
        message.error("Failed to restore client units texts!.");
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Error restoring client units:", error);
        message.error("Failed to restore client's all unit texts!");
      }
    } finally {
      clearLoadingMessage();
    }
  };

  const handleRestoreUnitText = async (unitId) => {
    try {
      if (resetCancelTokenSource) {
        resetCancelTokenSource.cancel("Request canceled due to new request.");
      }
      resetCancelTokenSource = axios.CancelToken.source();
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/shared-links/reset-unit-text/${unitId}`,
        {},
        {
          cancelToken: resetCancelTokenSource.token,
        }
      );

      if (response?.data?.success) {
        message.success("Unit's texts restored!");
        const updatedUnit = response?.data?.data || null;
        // getSharedUnitsForClient();
        setDataList((prevDataList) =>
          prevDataList.map((unit) =>
            unit.id === updatedUnit?.id ? { ...unit, ...updatedUnit } : unit
          )
        );
      } else {
        message.error("Failed to restore unit's text.");
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Error restoring unit text:", error);
        message.error("Failed to restore unit's text.");
      }
    }
  };

  // const handleRestoreAllSharedUnitsText = async () => {
  //   try {
  //     const response = await axios.put(
  //       `${process.env.REACT_APP_API_URL}/shared-links/update-all-shared-units-text`
  //     );

  //     if (response?.data?.success) {
  //       message.success("All shared unit texts restored successfully!");
  //       // getSharedUnitsForClient();
  //     } else {
  //       message.error("Failed to restore all shared unit texts.");
  //     }
  //   } catch (error) {
  //     console.error("Error restoring all shared units:", error);
  //     message.error("Error restoring all shared units texts!");
  //   }
  // };

  const getSharedUnitsForClient = async () => {
    setDataLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shared-links/get-all-shared-units-by-client/${clientId}`
      );
      if (response?.data?.data?.length) {
        setDataList(response.data.data);
      } else {
        setDataList([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDataLoading(false);
    }
  };

  const handleSaveEdit = async () => {
    try {
      setSaveLoading(true);
      const result = await axios.put(
        `${process.env.REACT_APP_API_URL}/shared-links/update-shared-unit/${editingUnit.id}`,
        { updatedTexts }
      );
      console.log(result.data, "tststs");
      if (result && result?.data?.success) {
        const { data = [] } = result?.data || {};
        setDataList((prevDataList) =>
          prevDataList.map((unit) =>
            unit?.id === data?.id ? { ...unit, ...data } : unit
          )
        );

        message.success("Unit's texts updated!");
      } else {
        message.error("Failed to update unit's text.");
      }

      handleCloseDialog();
    } catch (error) {
      console.error("Error updating unit:", error);
      message.error("Failed to update unit's text.");
    } finally {
      setSaveLoading(false);
    }
  };

  const handleDeleteUnit = async (unitId) => {
    try {
      if (deleteCancelTokenSource) {
        deleteCancelTokenSource.cancel("Request canceled due to new request.");
      }
      deleteCancelTokenSource = axios.CancelToken.source();
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/shared-links/delete-shared-unit/${unitId}`,
        {},
        {
          cancelToken: deleteCancelTokenSource.token,
        }
      );
      if (res?.data?.success) {
        message.success("Shared unit deleted successfully!");
        setDataList((prevDataList) =>
          prevDataList?.filter((unit) => unit?.id !== unitId)
        );
      } else {
        message.error("Failed to delete shared unit.");
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Error deleting unit:", error);
        message.error("Error deleting shared unit!");
      }
    }
  };

  return (
    <Box p={2}>
      {dataLoading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : dataList && dataList?.length ? (
        <>
          {/* Client Name Heading */}
          <div className="flex items-center justify-between mb-2">
            <Typography variant="h6" gutterBottom>
              {`${dataList[0]?.client?.firstname || ""} ${
                dataList[0]?.client?.lastname || ""
              }`}
            </Typography>

            <div className="flex items-center gap-3 px-4">
              <Tooltip title="Refresh">
                <IconButton
                  size="small"
                  onClick={() => getSharedUnitsForClient()}
                  sx={{
                    backgroundColor: "#E3F2FD",
                    "&:hover": { backgroundColor: "#BBDEFB" },
                  }}
                >
                  <RefreshIcon sx={{ color: "#1976D2" }} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Reset all text to original">
                <IconButton
                  size="small"
                  onClick={() => handleRestoreClientUnitsText(clientId)}
                  sx={{
                    backgroundColor: "#FFF3E0",
                    "&:hover": { backgroundColor: "#FFE0B2" },
                  }}
                >
                  <RestoreIcon sx={{ color: "#FB8C00" }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <Grid container spacing={2}>
            {dataList.map((unit) => (
              <Grid item xs={12} key={unit.id}>
                <Card
                  variant="outlined"
                  sx={{
                    p: 1.5,
                    borderRadius: 2,
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    transition: "0.3s",
                    "&:hover": {
                      boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.15)",
                    },
                  }}
                >
                  <CardContent sx={{ pb: 1 }}>
                    {/* Property Name */}
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        color: "#1976D2",
                        mb: 1,
                      }}
                    >
                      {unit?.units?.property_name || "Unknown Property"}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontStyle: "italic", mb: 1 }}
                    >
                      {`#${unit?.units?.unit || ""} • ${
                        unit?.units?.model_name || "N/A"
                      }`}
                    </Typography>

                    {/* Unit Details */}
                    {[
                      { label: "Title", value: unit?.title_text || "N/A" },
                      {
                        label: "Details",
                        value: unit?.sub_market_bd_ba_sqft_text || "N/A",
                      },
                      { label: "Price", value: unit?.price_text || "N/A" },
                      {
                        label: "Special Price",
                        value: unit?.special_price_text || "N/A",
                      },
                      {
                        label: "Special Calculation",
                        value: unit?.special_calc_text || "N/A",
                      },
                      { label: "Model", value: unit?.unit_model_text || "N/A" },
                      {
                        label: "Availability",
                        value: unit?.availability_holdtime_text || "N/A",
                      },
                    ].map((item, index) => (
                      <Box
                        key={index}
                        display="flex"
                        justifyContent="space-between"
                        mb={0.5}
                        sx={{
                          backgroundColor:
                            index % 2 === 0 ? "#F9F9F9" : "#F5F5F5",
                          padding: "4px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography variant="body2" sx={{ fontWeight: 500 }}>
                          {item.label}:
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "200px",
                          }}
                        >
                          {item.value}
                        </Typography>
                      </Box>
                    ))}

                    {/* Icon Buttons */}
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      mt={1}
                    >
                      <Tooltip title="Reset to original">
                        <IconButton
                          size="small"
                          onClick={() => handleRestoreUnitText(unit.id)}
                          sx={{
                            backgroundColor: "#FFF3E0",
                            "&:hover": { backgroundColor: "#FFE0B2" },
                          }}
                        >
                          <RestoreIcon sx={{ color: "#FB8C00" }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Edit">
                        <IconButton
                          size="small"
                          onClick={() => handleEditClick(unit)}
                          sx={{
                            ml: 1,
                            backgroundColor: "#E3F2FD",
                            "&:hover": { backgroundColor: "#BBDEFB" },
                          }}
                        >
                          <EditIcon sx={{ color: "#1976D2" }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteUnit(unit.id)}
                          sx={{
                            ml: 1,
                            backgroundColor: "#FFEBEE",
                            "&:hover": { backgroundColor: "#FFCDD2" },
                          }}
                        >
                          <DeleteIcon sx={{ color: "#D32F2F" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Edit Dialog */}
          <Dialog
            open={openEditDialog}
            onClose={() => {
              if (saveLoading) return;
              handleCloseDialog();
            }}
            maxWidth="sm"
            fullWidth
            sx={{
              "& .MuiPaper-root": {
                borderRadius: 3,
                padding: "12px 16px",
              },
            }}
          >
            <DialogTitle
              sx={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                paddingBottom: "8px",
                color: "#333",
              }}
            >
              Edit Unit
            </DialogTitle>

            <DialogContent
              dividers
              sx={{
                padding: "12px 16px",
                // backgroundColor: "#F9FAFB",
              }}
            >
              <Grid container spacing={1.5}>
                {Object.keys(updatedTexts).map((field) => (
                  <Grid item xs={12} key={field}>
                    <TextField
                      label={field.replace(/_/g, " ").toUpperCase()}
                      value={updatedTexts[field]}
                      onChange={(e) => handleChange(field, e.target.value)}
                      fullWidth
                      variant="outlined"
                      size="small"
                      margin="dense"
                      multiline={field === "special_calc_text"}
                      rows={field === "special_calc_text" ? 3 : 1}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          // borderRadius: 2,
                          backgroundColor: "#fff",
                          "&:hover fieldset": {
                            borderColor: "#64B5F6",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1976D2",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "0.85rem",
                        },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </DialogContent>

            <DialogActions
              sx={{
                padding: "8px 16px",
                justifyContent: "flex-end",
                // backgroundColor: "#F5F5F5",
              }}
            >
              <Button
                onClick={handleSaveEdit}
                variant="contained"
                color="primary"
                size="small"
                disabled={saveLoading}
                sx={{
                  // borderRadius: 2,
                  textTransform: "none",
                  backgroundColor: "#1976D2",
                  "&:hover": {
                    backgroundColor: "#1565C0",
                  },
                }}
              >
                {saveLoading ? "Saving..." : "Save"}
              </Button>
              <Button
                size="small"
                onClick={handleCloseDialog}
                variant="outlined"
                disabled={saveLoading}
                sx={{
                  // borderRadius: 2,
                  textTransform: "none",
                  ml: 1,
                  "&:hover": {
                    backgroundColor: "#F5F5F5",
                  },
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <Empty description="No Data Available" />
        </div>
      )}
    </Box>
  );
};

export default EditSharedUnits;
