import React, { useState, useEffect } from "react";
import axios from "axios";
import PropertyTable from "./tables/propertyTable";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
  Slider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  TextField,
  Menu,
  Popover,
  Typography,
  Autocomplete,
  Tooltip,
  Snackbar,
  IconButton,
} from "@mui/material";
import useStore from "../store";
import PropertyMap from "./maps/propertyMap";
import ClearIcon from "@mui/icons-material/Clear";
import _debounce from "lodash/debounce";
import { ShareAltOutlined } from "@ant-design/icons";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import ClientPortalAnalytics from "./sharedUnits/ClientPortalAnalytics";

let propertySearchCancelToken;
const NewSearch = () => {
  const navigate = useNavigate();

  const user = useStore((state) => state.user);

  const [previousProperty, setPreviousProperty] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [paginationModel, setPaginationModel] = useState(null);
  const [sortModel, setSortModel] = React.useState([]);
  const [searchName, setSearchName] = useState("");
  const [isNewFilterOpen, setIsNewFilterOpen] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedFilterCategory, setSelectedFilterCategory] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [marketField, setMarketField] = useState([]);
  const [loading, setLoading] = useState(true);
  const [analytics, setAnalytics] = useState(false);
  const [unitsFilterValue, setUnitFiltersValue] = useState({
    availability: {
      from: null,
      to: null,
    },
    sq_ft: {
      from: null,
      to: null,
    },
    bedrooms: {
      studio: false,
      one_bed: false,
      two_bed: false,
      three_bed: false,
      four_plus_bed: false,
    },
    convertable: false,
    unitPrice: {
      from: null,
      to: null,
    },
    current: 0,
  });
  const [clientDetails, setClientDetails] = useState({
    clientName: "",
    lastname: "",
    phone: "",
    email: "",
    month: "",
    budget: null,
    bedrooms: [],
  });
  const [maxPriceRange, setMaxPriceRange] = useState(10000);

  const findMinMax = (field) => {
    const values = properties
      .map((property) => property[field])
      .filter((value) => value != null);
    return {
      min: values.length > 0 ? Math.min(...values) : 0,
      max: values.length > 0 ? Math.max(...values) : 0,
    };
  };

  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const initialFilters = {
    availability: [],
    sq_ft: [],
    // For Location group - initialized as empty arrays for multi-select
    market: ["DFW"],
    submarket: [],
    city: [],
    state: [],
    zip_code: [],

    // For Units group - initialized as booleans
    studio: false,
    one_bed: false,
    two_bed: false,
    three_bed: false,
    four_plus_bed: false,
    single_family: false,
    townhome: false,
    high_rise: false,
    // For Amenities group - initialized as booleans
    yard: false,
    loft: false,
    garage: false,
    study: false,
    desk: false,
    ev_charger: false,
    fireplace: false,
    no_carpet: false,
    tub_shower: false,
    industrial: false,
    concrete_floors: false,
    furnished: false,
    high_ceilings: false,
    terrace: false,
    roof_terrace: false,
    floor_to_ceiling_windows: false,
    concierge: false,
    washer_dryer_in_unit: false,
    washer_dryer_connections: false,
    washer_dryer_in_building: false,

    year_built: [1990],
    year_renovated: [],
    number_floors: [],
    google_rating: [3.5, 5],
    price_range: [],
    admin_fee: [],
    application_fee: [],
    send: [],
    escort: [],
    bonus: [],

    shape: [],
    category: null,
    one_and_a_half_bath: false,
    studio_price: [],
    one_bed_price: [],
    two_bed_price: [],
    three_bed_price: [],
    four_plus_bed_price: [],
    escort_type: "percentage",
    send_type: "percentage",
    min_price: null,
    max_price: null,
  };

  const [filters, setFilters] = useState(initialFilters);
  const [view, setView] = useState("table");
  const [searchId, setSearchId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsData, setSearchParamsData] = useState(null);
  const [savedSearches, setSavedSearches] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [driveData, setDriveData] = useState([]);

  useEffect(() => {
    getDriveData();
  }, []);

  const getDriveData = async () => {
    const response = await axios
      .post(`${process.env.REACT_APP_API_URL}/searchFolder`, {
        folderPath: "",
      })
      .then(async (res) => {
        setDriveData(res.data);
      });
  };

  function getCurrentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  const initializeSliderValues = (searchid) => {
    const getMinMaxArray = (field) => {
      const minMax = findMinMax(field);
      return [minMax.min, minMax.max];
    };
    // Default values
    let initialValues = {
      year_built: [1990, getCurrentYear()],
      year_renovated: [
        getMinMaxArray("year_renovated")[0],
        getMinMaxArray("year_renovated")[1],
      ],
      number_floors: getMinMaxArray("number_floors"),
      google_rating: [3.5, 5],
      price_range: [0, maxPriceRange],
      admin_fee: getMinMaxArray("admin_fee"),
      application_fee: getMinMaxArray("application_fee"),
      send: getMinMaxArray("send"),
      escort: getMinMaxArray("escort"),
      bonus: getMinMaxArray("bonus"),
    };

    if (searchid) {
      Object.keys(initialValues).forEach((key) => {
        if (filters[key] && filters[key].length === 2) {
          initialValues[key] = filters[key];
        }
      });
    }

    return initialValues;
  };

  const [sliderValues, setSliderValues] = useState(
    initializeSliderValues(searchId)
  );
  const [tempSliderValues, setTempSliderValues] = useState(sliderValues);

  useEffect(() => {
    fetchSavedSearches();
  }, []);

  const handleSubmitNewClient = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/client/addClient`,
        clientDetails
      );
      if (response.data == "Email Already Exist") {
        setShowAlert(true);
      } else {
        setShowAlert(false);
        setClientDetails((prev) => ({ ...prev, month: "", bedrooms: "" }));
        setSelectedClient(response?.data?.id);
        // fetchClients();
        setIsModalOpen(false);
        setFilters((prev) => ({
          ...prev,
          price_range: [0, response?.data?.budget],
          one_bed: response?.data?.no_of_bedrooms.includes("One"),
          two_bed: response?.data?.no_of_bedrooms.includes("Two"),
          three_bed: response?.data?.no_of_bedrooms.includes("Three"),
          four_plus_bed: response?.data?.no_of_bedrooms.includes("Four +"),
          studio: response?.data?.no_of_bedrooms.includes("Studio"),
        }));

        setTempSliderValues((prev) => ({
          ...prev,
          price_range: [0, response?.data?.budget],
        }));

        await storeSearch(
          response?.data?.id,
          response?.data?.firstname,
          response?.data?.budget
        );
      }
    } catch (error) {
      console.error("Error creating client:", error);
    }
  };

  const fetchSavedSearches = async (e) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/searches/${user?.id}`
      );
      setSavedSearches(response?.data);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    const currentSearchId = searchParams.get("searchId");
    setSearchId(currentSearchId);
    if (currentSearchId) {
      fetchSearch(currentSearchId);
    }
    fetchData();
  }, [searchParams]);

  useEffect(() => {
    getFiltersDetails();
  }, []);

  const getFiltersDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getFiltersDetails`
      );

      setMarketField(response?.data?.marketFields);
      setMaxPriceRange(response?.data?.overallMaxPrice);
    } catch (error) {
      console.error("Error fetching FiltersDetails:", error);
    }
  };

  useEffect(() => {
    if (savedSearches?.length > 0) {
      const title = savedSearches?.find(
        (x) => x?.id?.toString() === searchParams.get("searchId")
      );
      setSearchParamsData(title);
      setSelectedClient(title?.client_id);
    }
  }, [searchParams, savedSearches]);

  const refreshTable = (data) => {
    fetchData();
  };

  const fetchData = _debounce(async () => {
    const type = "search";
    try {
      const filterData = {
        selectedFilterCategory,
        searchValue,
        sortData: sortModel?.at(0),
        filters,
        type,
      };

      setLoading(true);
      if (propertySearchCancelToken) {
        propertySearchCancelToken.cancel("Operation canceled by the user.");
      }
      propertySearchCancelToken = axios.CancelToken.source();

      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/listProperties?page=${paginationModel?.page}&pageSize=${paginationModel?.pageSize}`,
        filterData,
        {
          cancelToken: propertySearchCancelToken?.token,
        }
      );
      setLoading(false);
      // const sortedProperties = result?.data?.rows?.sort(
      //   (a, b) => b.send - a.send
      // );
      const sortedProperties = result?.data?.rows || [];
      //setProperties([]);
      setProperties(sortedProperties);
      if (sortedProperties.length > 0) {
        setPreviousProperty(sortedProperties[0]);
      }
      setTotalRows(result?.data?.totalRows);
      // setBuildingClass(result?.data?.uniqueBuildingClasses);
      // setMarketField(result?.data?.uniqueMarketFields);
      return result;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching properties:", error);
    }
  }, 1000);

  const resetPage = () => {
    setPaginationModel((prev) => {
      if (prev && prev?.pageSize) {
        return {
          pageSize: prev?.pageSize,
          page: 0,
        };
      }
      return {
        pageSize: 100,
        page: 0,
      };
    });
  };

  useEffect(() => {
    resetPage();
  }, [sortModel, filters]);

  useEffect(() => {
    if (paginationModel) fetchData();
  }, [paginationModel]);

  useEffect(() => {
    if (searchValue?.length > 2 || searchValue?.length === 0) {
      resetPage();
    }
  }, [searchValue]);

  const fetchSearch = async (searchId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/search/${searchId}`
      );
      const searchFilters = response.data.filters;
      setTempSliderValues((prevState) => ({
        ...prevState,
        price_range: [
          searchFilters?.min_price !== undefined &&
          searchFilters?.min_price !== null
            ? searchFilters?.min_price
            : prevState.price_range[0],
          searchFilters?.max_price !== undefined &&
          searchFilters?.max_price !== null
            ? searchFilters?.max_price
            : prevState.price_range[1],
        ],

        availability: [],

        year_built:
          searchFilters?.year_built?.length > 1
            ? [searchFilters?.year_built[0], searchFilters.year_built[1]]
            : [prevState.year_built[0], prevState?.year_built[1]],
        google_rating:
          searchFilters?.google_rating?.length > 0
            ? [searchFilters?.google_rating[0], searchFilters?.google_rating[1]]
            : [prevState?.google_rating[0], prevState?.google_rating[1]],
      }));

      setFilters(searchFilters);
      setSelectedFilterCategory(searchFilters?.category);
    } catch (error) {
      console.error("Error fetching search:", error);
    }
  };

  useEffect(() => {
    const autoSaveSearch = async () => {
      const isDifferentFromInitial = Object.entries(filters).some(
        ([key, value]) => {
          if (Array.isArray(value)) {
            return (
              value.length !== initialFilters[key].length ||
              value.some((val, index) => val !== initialFilters[key][index])
            );
          }
          return value !== initialFilters[key];
        }
      );
      if (isDifferentFromInitial && searchId) {
        await updateSearch(filters, searchId);
      }
    };
    if (Object.values(filters).some((value) => value)) {
      autoSaveSearch();
    }
  }, [filters, searchId]);

  const storeSearch = async (id, name, maxPrice) => {
    try {
      const { price_range, ...otherFilters } = filters;

      const modifiedFilters = {
        ...otherFilters,
        price_range,
        min_price: price_range?.[0] || 0,
        max_price: maxPrice || price_range?.[1],
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/search`,
        {
          filters: modifiedFilters,
          client: id,
          user: user?.id,
          searchName: name,
        }
      );
      setSearchId(response?.data?.id);
      setSearchParams({ searchId: response?.data?.id });
      fetchSavedSearches();
    } catch (error) {
      console.error("Error storing search:", error);
    }
  };

  const updateSearch = async (filters, searchId) => {
    const { price_range, ...otherFilters } = filters;

    const modifiedFilters = {
      ...otherFilters,
      price_range,
      min_price: price_range?.[0],
      max_price: price_range?.[1],
    };
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/search/${searchId}`, {
        filters: modifiedFilters,
        client: selectedClient,
        user: user.id,
      });
    } catch (error) {
      console.error("Error updating search:", error);
    }
  };

  const handleSearchSelect = (event, selectedSearch) => {
    const selectedSearchId = selectedSearch?.id || "";
    if (!selectedSearchId && selectedSearchId !== 0) {
      setSelectedClient("");
      setFilters(initialFilters);
      return navigate(`/search`);
    }
    navigate(`/search?searchId=${selectedSearchId}`);
    setSelectedClient(
      savedSearches.find((search) => search?.id === selectedSearchId).client_id
    );
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const createSharedLink = async () => {
    try {
      const encodedClientId = btoa(encodeURIComponent(selectedClient));
      const utfUrl = `${window.location.origin}/shared-units?client=${encodedClientId}`;

      await navigator.clipboard.writeText(utfUrl);
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFilterChange = (event) => {
    const { name, value, checked } = event.target;
    if (Array.isArray(filters[name])) {
      setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    } else {
      setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    }

    if (typeof filters[name] === "boolean") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: checked,
      }));
    } else if (Array.isArray(filters[name])) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: event.target.value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: event.target.value,
      }));
    }
    let data = unitsFilterValue;
    if (name == "studio" && checked) {
      data.bedrooms.studio = true;
    } else if (name == "studio") {
      data.bedrooms.studio = false;
    }
    if (name == "one_bed" && checked) {
      data.bedrooms.one_bed = true;
    } else if (name == "one_bed") {
      data.bedrooms.one_bed = false;
    }
    if (name == "two_bed" && checked) {
      data.bedrooms.two_bed = true;
    } else if (name == "two_bed") {
      data.bedrooms.two_bed = false;
    }
    if (name == "three_bed" && checked) {
      data.bedrooms.three_bed = true;
    } else if (name == "three_bed") {
      data.bedrooms.three_bed = false;
    }
    if (name == "four_plus_bed" && checked) {
      data.bedrooms.four_plus_bed = true;
    } else if (name == "four_plus_bed") {
      data.bedrooms.four_plus_bed = false;
    }
    setUnitFiltersValue(data);
  };

  const selectFieldGroups = {
    Location: ["market", "submarket", "city", "state", "zip_code"],
  };

  const booleanFieldGroups = {
    Amenities: [
      "yard",
      "loft",
      "garage",
      "study",
      "desk",
      "ev_charger",
      "fireplace",
      "no_carpet",
      "tub_shower",
      "industrial",
      "concrete_floors",
      "furnished",
      "high_ceilings",
      "terrace",
      "roof_terrace",
      "floor_to_ceiling_windows",
      "concierge",
      "washer_dryer_in_unit",
      "washer_dryer_connections",
      "washer_dryer_in_building",
      "one_and_a_half_bath",
      "high_rise",
      "townhome",
      "single_family",
    ],
    Units: ["studio", "one_bed", "two_bed", "three_bed", "four_plus_bed"],
  };

  const formatLabel = (label) =>
    label
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word?.slice(1))
      .join(" ");

  const handleSliderChange = (field) => (event, newValue) => {
    setTempSliderValues((prev) => ({ ...prev, [field]: newValue }));
  };

  const handleSliderChangeCommitted = (field) => (event, newValue) => {
    setSliderValues((prev) => ({ ...prev, [field]: newValue }));
    setFilters((prevFilters) => ({ ...prevFilters, [field]: newValue }));
  };

  const sliderFields = {
    google_rating: {
      label: "Google Rating",
      min: 0,
      max: 5,
      step: 0.1,
    },
  };

  const rangeFields = {
    year_built: {
      label: "Year Built",
      min: {
        label: "Min",
      },
      max: {
        label: "Max",
      },
    },
  };

  const unitRangeFields = {
    price_range: {
      label: "Price Range",
      min: {
        label: "Min",
      },
      max: {
        label: "Max",
      },
    },
    availability: {
      type: "date",
      label: "Availability Date",
      min: {
        label: "Min",
      },
      max: {
        label: "Max",
      },
    },
    sq_ft: {
      type: "number",
      label: "Unit Size/Sq ft",
      min: {
        label: "Min",
      },
      max: {
        label: "Max",
      },
    },
  };

  useEffect(() => {
    setSliderValues(initializeSliderValues(searchId));
    setTempSliderValues((prevState) => ({
      ...prevState,
      price_range: [prevState.price_range[0], maxPriceRange],
    }));
  }, [maxPriceRange]);

  const getUniqueValues = (key) => {
    const unique = new Set(
      properties
        .map((item) => item[key])
        .filter((value) => value !== null && value !== "")
    );
    return [...unique].sort();
  };

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(properties);
  }, [properties]);

  const handleCategoryChange = (e) => {
    const value = e?.target?.value !== undefined ? e?.target?.value : e;
    setSelectedFilterCategory(value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      category: value,
    }));

    setCategoryAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value || "");
  };

  const handleClick = (event) => {
    setCategoryAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setCategoryAnchorEl(null);
  };

  const isCategoryPopoverOpen = Boolean(categoryAnchorEl);
  const id = isCategoryPopoverOpen ? "category-popover" : undefined;

  const options = [
    "-",
    "Not working with locators",
    "Student Housing",
    "Affordable Housing",
    "Do not add",
    "Unsure",
  ];

  const [amenitiesAnchor, setAmenitiesAnchor] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handlePopoverOpen = (event, group) => {
    setAmenitiesAnchor(event.currentTarget);
    setSelectedGroup(group);
  };

  const handleAmenitiesClose = () => {
    setAmenitiesAnchor(null);
    setSelectedGroup(null);
  };

  const isAmenitiesOpen = Boolean(amenitiesAnchor);
  const amenitiesPopoverId = isAmenitiesOpen
    ? `boolean-field-popover-${selectedGroup}`
    : undefined;

  const handleClearFilters = () => {
    setSearchValue("");
    setSelectedFilterCategory(null);
    setFilters(initialFilters);
    setSliderValues(initializeSliderValues(searchId));
  };

  const renderFilteredUnits = () => {
    const filteredUnits = booleanFieldGroups.Units.filter(
      (field) => filters[field] || false
    );

    return (
      <div className="text-slate-500 text-xs line-clamp-1">
        {filteredUnits?.slice(0, 2).map((field, index, array) => (
          <span key={field}>
            {field}
            {index < array.length - 1 && ", "}
          </span>
        ))}

        {filteredUnits.length > 2 && (
          <span>
            (+
            {filteredUnits.length - 2})
          </span>
        )}

        {filteredUnits.length === 0 && "-"}
      </div>
    );
  };

  const renderFilteredAmenities = () => {
    const filteredAmenities = booleanFieldGroups.Amenities.filter(
      (field) => filters[field] || false
    );

    return (
      <div className="text-slate-500 text-xs line-clamp-1">
        {filteredAmenities?.slice(0, 2).map((field, index, array) => (
          <span key={field}>
            {field}
            {index < array.length - 1 && ", "}
          </span>
        ))}
        {filteredAmenities.length > 2 && (
          <span>
            (+
            {filteredAmenities.length - 2})
          </span>
        )}
        {filteredAmenities.length === 0 && "-"}
      </div>
    );
  };

  const [neighborsAnchor, setNeighborsAnchor] = useState(null);

  const handleNeighborsOpen = (event) => {
    setNeighborsAnchor(event.currentTarget);
  };

  const handleNeighborsClose = () => {
    setNeighborsAnchor(null);
  };

  const getFilterLabel = (search) => {
    if (search?.client?.firstname && search?.client?.lastname) {
      return `${search?.client?.firstname} ${search?.client?.lastname}`;
    } else if (search?.client && search?.client?.name) {
      return `${search?.title} (${search?.client?.name})`;
    } else {
      return search?.title;
    }
  };

  const bedrooms = ["Studio", "One", "Two", "Three", "Four +"];

  const handleClientChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formValue = value;
    if (name === "clientName") {
      formValue = value.trim().replace(/^0+/, "");
    }
    if (name === "budget") {
      formValue = Number(value);
    }
    setClientDetails((prev) => ({
      ...prev,
      [name]: formValue,
    }));
  };

  const handleChange = (fieldName, index, value) => {
    if (fieldName == "availability") {
      setFilters((prev) => ({
        ...prev,
        [fieldName]: [
          ...prev[fieldName]?.slice(0, index),
          value,
          ...prev[fieldName]?.slice(index + 1),
        ],
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [fieldName]: [
          ...prev[fieldName]?.slice(0, index),
          Number(value),
          ...prev[fieldName]?.slice(index + 1),
        ],
      }));
    }
    let data = unitsFilterValue;
    if (fieldName == "price_range") {
      if (index == 0) {
        data.unitPrice.from = value;
      } else {
        data.unitPrice.to = value;
      }
    }
  };

  const handleUploadClick = () => {
    navigate("/admin");
  };

  return (
    <div className="">
      <hr />
      <div className="flex  bg-sidekick-primary justify-center sticky-search-bar ">
        <div className="flex w-8/12 item-center justify-between px-2  my-2 bg-white rounded-3xl grow-0">
          <div className=" p-2  h-3/4">
            <input
              value={searchValue}
              onChange={handleSearchChange}
              className=" h-10 w-[310px] rounded-3xl border border-black/30 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600  focus:outline-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
              type="text"
              placeholder="   Search by name"
            ></input>
          </div>
          <div className="mt-2 ">
            <Typography
              variant="contained"
              onClick={handleNeighborsOpen}
              sx={{ cursor: "pointer" }}
            >
              Neighborhood{" "}
              <div className="text-slate-500 text-xs">
                {Object.entries(selectFieldGroups).map(
                  ([groupName, fields]) => (
                    <span key={groupName}>
                      {fields
                        .filter((field) => filters[field]?.length > 0)
                        .map((field) => filters[field][0])
                        .slice(0, 1)
                        .join(", ") || "-"}
                    </span>
                  )
                )}
              </div>
            </Typography>

            <Popover
              open={Boolean(neighborsAnchor)}
              anchorEl={neighborsAnchor}
              onClose={handleNeighborsClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div>
                {Object.entries(selectFieldGroups).map(
                  ([groupName, fields]) => (
                    <Box key={groupName} sx={{ mb: 2 }} className="mt-3">
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                        {fields.map((field) => (
                          <FormControl
                            key={field}
                            variant="outlined"
                            sx={{ m: 1, minWidth: 120 }}
                          >
                            <InputLabel>{formatLabel(field)}</InputLabel>
                            <Select
                              multiple
                              value={
                                Array.isArray(filters[field])
                                  ? filters[field]
                                  : []
                              }
                              onChange={handleFilterChange}
                              label={formatLabel(field)}
                              name={field}
                              renderValue={(selected) => selected.join(", ")}
                            >
                              {field === "market" &&
                                marketField.map((value, index) => (
                                  <MenuItem key={index} value={value}>
                                    {value}
                                  </MenuItem>
                                ))}
                              {field !== "market" &&
                                getUniqueValues(field).map((value, index) => (
                                  <MenuItem key={index} value={value}>
                                    {value}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        ))}
                      </Box>
                    </Box>
                  )
                )}
              </div>
            </Popover>
          </div>

          <div className="mt-2 ">
            <Typography
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              sx={{ cursor: "pointer" }}
            >
              Category{" "}
              <div className="text-slate-500 text-xs">
                {selectedFilterCategory ?? "-"}
              </div>
            </Typography>
            <Popover
              id={id}
              open={isCategoryPopoverOpen}
              anchorEl={categoryAnchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box p={2}>
                {options.map((option) => (
                  <Typography
                    key={option}
                    onClick={() =>
                      handleCategoryChange(option === "-" ? null : option)
                    }
                    sx={{ cursor: "pointer", mb: 1 }}
                  >
                    {option}
                  </Typography>
                ))}
              </Box>
            </Popover>
          </div>

          <div
            className="mt-2 "
            onClick={(e) => handlePopoverOpen(e, "Units")}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="contained mt-2">
              Beds{" "}
              <div className="text-slate-500 text-xs line-clamp-1">
                {renderFilteredUnits()}
              </div>
            </Typography>
          </div>
          <Popover
            id={amenitiesPopoverId}
            open={isAmenitiesOpen && selectedGroup === "Units"}
            anchorEl={amenitiesAnchor}
            onClose={handleAmenitiesClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box p={2} className="flex flex-col">
              {booleanFieldGroups.Units.map((field) => (
                <FormControlLabel
                  key={field}
                  control={
                    <Checkbox
                      checked={filters[field] || false}
                      onChange={handleFilterChange}
                      name={field}
                    />
                  }
                  label={field}
                />
              ))}
            </Box>
          </Popover>

          <div
            className="mt-2 "
            onClick={(e) => handlePopoverOpen(e, "Amenities")}
            style={{ cursor: "pointer" }}
          >
            <Typography variant="contained mt-2">
              Amenities
              {renderFilteredAmenities()}
            </Typography>
          </div>
          <Popover
            id={amenitiesPopoverId}
            open={isAmenitiesOpen && selectedGroup === "Amenities"}
            anchorEl={amenitiesAnchor}
            onClose={handleAmenitiesClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box p={2}>
              <div
                style={{
                  columnCount: 2,
                  columnGap: "16px",
                  breakInside: "avoid",
                  width: 390,
                  paddingRight: "84px",
                }}
              >
                {booleanFieldGroups.Amenities.map((field) => (
                  <FormControlLabel
                    key={field}
                    control={
                      <Checkbox
                        checked={filters[field] || false}
                        onChange={handleFilterChange}
                        name={field}
                      />
                    }
                    label={field}
                  />
                ))}
              </div>
            </Box>
          </Popover>
          <div
            className="my-1 pt-[10px]  px-3 rounded-full bg-red-600"
            onClick={handleClearFilters}
            style={{ cursor: "pointer" }}
          >
            <ClearIcon style={{ color: "#ffffff" }} />
          </div>
        </div>{" "}
      </div>
      <div>
        <div className="flex justify-between w-full items-center mb-4 mt-[85px] px-4">
          <h2 className="text-2xl font-bold">New Search</h2>

          <Box sx={{ display: "flex", gap: 2 }}>
            {selectedClient && (
              <Tooltip title="Share Units to Client">
                <IconButton color="primary" onClick={createSharedLink}>
                  <ShareAltOutlined style={{ color: "#1976D2" }} />
                </IconButton>
              </Tooltip>
            )}

            {selectedClient && (
              <Tooltip title="Client Portal">
                <IconButton onClick={()=>setAnalytics(true)} color="primary">
                  <AnalyticsOutlinedIcon style={{ color: "#1976D2" }} />
                </IconButton>
              </Tooltip>
            )}

            <Button variant="outlined" onClick={() => setIsModalOpen(true)}>
              Clone Search
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/search");
                setFilters(initialFilters);
                setTempSliderValues(initializeSliderValues(""));
                setSelectedClient("");
              }}
              style={{ marginRight: "8px" }}
            >
              Reset Filters
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUploadClick}
            >
              Upload
            </Button>
          </Box>
        </div>
        <Box className="px-4" sx={{ mb: 4 }}>
          <Accordion>
            <AccordionSummary>
              Filter Options ({filteredData.length})
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ mb: 4 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      mb: 2,
                    }}
                  >
                    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                      <Autocomplete
                        sx={{ width: 180 }}
                        value={searchParamsData}
                        onChange={handleSearchSelect}
                        options={savedSearches}
                        // onInputChange={fetchSavedSearches}
                        getOptionLabel={getFilterLabel}
                        renderInput={(params) => (
                          <TextField {...params} label="Saved Searches" />
                        )}
                      />
                    </FormControl>

                    <Box
                      sx={{ display: "flex", alignItems: "stretch", gap: 1 }}
                    >
                      <Button
                        sx={{ height: 50 }}
                        onClick={() => setIsModalOpen(true)}
                        variant="contained"
                      >
                        Add New Client
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <h3>Category</h3>
                <Box sx={{ minWidth: 20, marginTop: 1, marginBottom: 2 }}>
                  <FormControl>
                    {selectedFilterCategory !== null && (
                      <InputLabel id="demo-simple-select-label">
                        Category
                      </InputLabel>
                    )}
                    <Select
                      className="w-25"
                      displayEmpty
                      inputProps={{ "aria-label": "Category" }}
                      value={selectedFilterCategory ?? "null"}
                      label={selectedFilterCategory !== null ? "Category" : ""}
                      onChange={(e) => handleCategoryChange(e)}
                    >
                      <MenuItem style={{ color: "#396fd4" }} value={null}>
                        --Select a Category--
                      </MenuItem>
                      <MenuItem value="Not working with locators">
                        Not working with locators
                      </MenuItem>
                      <MenuItem value="Student Housing">
                        Student Housing
                      </MenuItem>
                      <MenuItem value="Affordable Housing">
                        Affordable Housing
                      </MenuItem>
                      <MenuItem value="Do not add">Do not add</MenuItem>
                      <MenuItem value="Unsure">Unsure</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {Object.entries(selectFieldGroups).map(
                  ([groupName, fields]) => (
                    <Box key={groupName} sx={{ mb: 2 }}>
                      <h3>{groupName}</h3>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                        {fields.map((field) => (
                          <FormControl
                            key={field}
                            variant="outlined"
                            sx={{ m: 1, minWidth: 120 }}
                          >
                            <InputLabel>{formatLabel(field)}</InputLabel>
                            <Select
                              multiple
                              value={
                                Array.isArray(filters[field])
                                  ? filters[field]
                                  : []
                              }
                              onChange={handleFilterChange}
                              label={formatLabel(field)}
                              name={field}
                              renderValue={(selected) => selected.join(", ")}
                            >
                              {field === "market"
                                ? marketField.map((value, index) => (
                                    <MenuItem key={index} value={value}>
                                      {value}
                                    </MenuItem>
                                  ))
                                : getUniqueValues(field).map((value, index) => (
                                    <MenuItem key={index} value={value}>
                                      {value}
                                    </MenuItem>
                                  ))}
                            </Select>
                          </FormControl>
                        ))}
                      </Box>
                    </Box>
                  )
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: 1,
                    width: 1 / 3,
                  }}
                >
                  {Object.entries(rangeFields).map(([fieldName, field]) => (
                    <div key={fieldName} className="mb-2">
                      <label>{field.label}</label>
                      <Box sx={{}}>
                        <TextField
                          type={field.type == "date" ? "date" : "number"}
                          value={filters[fieldName]?.[0] ?? ""}
                          sx={{ width: 100 }}
                          className="custom-text-field"
                          id={`${fieldName}-min`}
                          onChange={(e) =>
                            handleChange(fieldName, 0, e.target.value)
                          }
                          variant="outlined"
                        />

                        <span style={{ marginTop: "20px" }} className="p-2 ">
                          To
                        </span>

                        <TextField
                          style={{
                            border:
                              filters[fieldName]?.[1] <
                                filters[fieldName]?.[0] &&
                              filters[fieldName]?.[1] != 0
                                ? "1px solid red"
                                : "unset",
                            borderRadius:
                              filters[fieldName]?.[1] >
                                filters[fieldName]?.[0] &&
                              filters[fieldName]?.[1] != 0
                                ? "unset"
                                : "5px",
                          }}
                          value={filters[fieldName]?.[1] || ""}
                          type={field.type == "date" ? "date" : "number"}
                          sx={{ width: 100 }}
                          className="custom-text-field"
                          id={`${fieldName}-max`}
                          onChange={(e) =>
                            handleChange(fieldName, 1, e.target.value)
                          }
                          variant="outlined"
                        />
                      </Box>
                    </div>
                  ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: 1,
                    width: 1 / 3,
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  {Object.entries(sliderFields).map(([field, config]) => (
                    <Box key={field} sx={{ width: "45%", mb: 2 }}>
                      {config.label}
                      <Slider
                        value={tempSliderValues[field]}
                        onChange={handleSliderChange(field)}
                        onChangeCommitted={handleSliderChangeCommitted(field)}
                        valueLabelDisplay="auto"
                        min={config.min}
                        max={config.max}
                        step={config.step}
                        marks={config.marks}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 1,
                        }}
                      >
                        <span>{tempSliderValues[field][0]}</span>
                        <span>{tempSliderValues[field][1]}</span>
                      </Box>
                    </Box>
                  ))}
                </Box>

                {/* Boolean filter groups */}
                {Object.entries(booleanFieldGroups).map(
                  ([groupName, fields]) => (
                    <Box key={groupName} sx={{ mb: 2 }}>
                      <h3>{groupName}</h3>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                        {fields.map((field) => (
                          <FormControlLabel
                            key={field}
                            control={
                              <Checkbox
                                checked={filters[field] || false}
                                onChange={handleFilterChange}
                                name={field}
                              />
                            }
                            label={
                              field !== "one_and_a_half_bath"
                                ? formatLabel(field)
                                : "1.5 Bath"
                            }
                          />
                        ))}
                      </Box>
                    </Box>
                  )
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {Object.entries(unitRangeFields).map(([fieldName, field]) => (
                    <div
                      key={fieldName}
                      className="mb-2"
                      style={{ marginRight: "15px" }}
                    >
                      <label>{field.label}</label>
                      <Box sx={{}}>
                        <TextField
                          style={{ width: field.type == "date" ? "20vh" : "" }}
                          type={field.type == "date" ? "date" : "number"}
                          value={filters[fieldName]?.[0] ?? ""}
                          sx={{ width: 100 }}
                          className="custom-text-field"
                          id={`${fieldName}-min`}
                          onChange={(e) =>
                            handleChange(fieldName, 0, e.target.value)
                          }
                          variant="outlined"
                        />

                        <span style={{ marginTop: "20px" }} className="p-2 ">
                          To
                        </span>

                        <TextField
                          style={{
                            width: field.type == "date" ? "20vh" : "",
                            border:
                              filters[fieldName]?.[1] <
                                filters[fieldName]?.[0] &&
                              filters[fieldName]?.[1] != 0
                                ? "1px solid red"
                                : "unset",
                            borderRadius:
                              filters[fieldName]?.[1] >
                                filters[fieldName]?.[0] &&
                              filters[fieldName]?.[1] != 0
                                ? "unset"
                                : "5px",
                          }}
                          value={filters[fieldName]?.[1] || ""}
                          type={field.type == "date" ? "date" : "number"}
                          sx={{ width: 100 }}
                          className="custom-text-field"
                          id={`${fieldName}-max`}
                          onChange={(e) =>
                            handleChange(fieldName, 1, e.target.value)
                          }
                          variant="outlined"
                        />
                      </Box>
                    </div>
                  ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box
          sx={{
            mb: 4,
            display: "flex",
            gap: 2,
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          className="px-4"
        >
          <p>{filteredData?.length} properties</p>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant={view === "table" ? "contained" : "outlined"}
              onClick={() => setView("table")}
            >
              Table View
            </Button>
            <Button
              variant={view === "split" ? "contained" : "outlined"}
              onClick={() => setView("split")}
            >
              Split View
            </Button>
            <Button
              variant={view === "map" ? "contained" : "outlined"}
              onClick={() => setView("map")}
            >
              Map View
            </Button>
          </Box>
          {filters?.shape?.length > 0 && (
            <Button
              variant="outlined"
              onClick={() => {
                setFilters((prevFilters) => {
                  const newFilters = { ...prevFilters, shape: [] };

                  return newFilters;
                });
              }}
            >
              Remove Map Filtering
            </Button>
          )}
        </Box>

        {view === "split" ? (
          <div className="flex">
            <div className="w-1/2 max-h-screen">
              {filteredData.length > 0 ? (
                <PropertyTable
                  driveData={driveData}
                  properties={properties}
                  setFilteredProperties={setProperties}
                  paginationModel={paginationModel}
                  setPaginationModel={setPaginationModel}
                  totalRows={totalRows}
                  sortModel={sortModel}
                  setSortModel={setSortModel}
                  fetchData={fetchData}
                  loading={loading}
                  unitFilter={unitsFilterValue}
                  selectedBoolean={filters}
                  refreshTable={refreshTable}
                  selectedClient={selectedClient}
                />
              ) : (
                <p className="w-1/2 min-h-[930px] max-h-screen">
                  No results please adjust your filters
                </p>
              )}
            </div>
            <div className="w-1/2 max-h-screen">
              <PropertyMap
                previousProperty={previousProperty}
                properties={properties}
                filters={filters}
                setFilters={setFilters}
                setFilteredProperties={setProperties}
              />
            </div>
          </div>
        ) : view === "table" ? (
          filteredData.length > 0 ? (
            <PropertyTable
              driveData={driveData}
              properties={filteredData}
              setFilteredProperties={setProperties}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              totalRows={totalRows}
              sortModel={sortModel}
              setSortModel={setSortModel}
              fetchData={fetchData}
              loading={loading}
              unitFilter={unitsFilterValue}
              selectedBoolean={filters}
              refreshTable={refreshTable}
              selectedClient={selectedClient}
            />
          ) : (
            <p>No results please adjust your filters</p>
          )
        ) : view === "map" ? (
          <div style={{ height: "80vh" }}>
            <PropertyMap
              previousProperty={previousProperty}
              properties={properties}
              filters={filters}
              setFilters={setFilters}
              setFilteredProperties={setFilteredProperties}
            />
          </div>
        ) : (
          <p>Loading Properties...</p>
        )}

        <Modal
          open={isModalOpen}
          // onClose={() => {
          //   setIsModalOpen(false);
          //   setClientDetails((prev) => ({ ...prev, month: "" }));
          // }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
            >
              <CloseIcon
                onClick={() => {
                  setIsModalOpen(false);
                  setClientDetails((prev) => ({ ...prev, month: "" }));
                }}
              />
            </div>
            <h1>Add Client</h1>
            <form onSubmit={handleSubmitNewClient}>
              {showAlert && <Alert severity="error">Email Already Exist</Alert>}
              <TextField
                label="First Name"
                fullWidth
                margin="normal"
                name="clientName"
                required
                onChange={handleClientChange}
              />
              <TextField
                label="Last Name"
                fullWidth
                margin="normal"
                name="lastname"
                required
                onChange={handleClientChange}
              />
              <TextField
                label="Phone"
                fullWidth
                margin="normal"
                name="phone"
                onChange={handleClientChange}
              />
              <TextField
                label="Email"
                type="email"
                name="email"
                fullWidth
                margin="normal"
                onChange={handleClientChange}
              />

              <TextField
                label="Move in Date"
                type="date"
                name="move_in_date"
                fullWidth
                margin="normal"
                onChange={handleClientChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControl variant="outlined" fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Number of Bedrooms</InputLabel>
                <Select
                  name="bedrooms"
                  fullWidth
                  inputProps={{ "aria-label": "Number of Bedrooms" }}
                  value={
                    Array.isArray(clientDetails?.bedrooms)
                      ? clientDetails?.bedrooms
                      : []
                  }
                  label={"Number of Bedrooms"}
                  multiple
                  onChange={handleClientChange}
                >
                  {bedrooms.map((bedroom, index) => (
                    <MenuItem key={index} value={bedroom}>
                      {bedroom}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label="Budget"
                type="number"
                name="budget"
                fullWidth
                margin="normal"
                onChange={handleClientChange}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
              >
                Add Client
              </Button>
            </form>
          </Box>
        </Modal>
        <Modal open={isNewFilterOpen} onClose={() => setIsNewFilterOpen(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <h1>Add Filter Name </h1>
            <div>
              <TextField
                label="Filter Name"
                fullWidth
                margin="normal"
                required
                value={searchName}
                onChange={(e) =>
                  setSearchName(e.target.value.trim().replace(/^0+/, ""))
                }
              />

              <Button
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => {
                  storeSearch();
                  setIsNewFilterOpen(false);
                  setSearchName("");
                }}
                disabled={!searchName}
              >
                Save Search
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
      <Snackbar
        open={snackbarOpen}
        style={{ right: 0, top: "65px" }}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseSnackbar}
        message="Copied to clipboard!"
      />
      <ClientPortalAnalytics
        analytics={analytics}
        setAnalytics={setAnalytics}
        clientId={selectedClient}
      />
    </div>
  );
};

export default NewSearch;
