import React from "react";

const YoutubeRenderer = ({ videoUrl = "" }) => {
  // Extract video ID from the URL
  const videoId = videoUrl?.split("v=")[1]?.split("&")[0];

  return (
    <div className="w-full h-full flex items-center justify-center">
      {videoId ? (
        <iframe
          className="w-full h-full"
          style={{ borderRadius: "8px" }}
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <p className="text-red-500">Invalid YouTube URL</p>
      )}
    </div>
  );
};

export default YoutubeRenderer;
