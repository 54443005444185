import React from "react";

const SharedUnitsFooter = () => {
  return (
    <>
      <div className="h-16 bg-[#596962] text-[#FFFFFF] text-wrap text-center text-[14px] flex items-center justify-center px-4">
        Pricing, specials and availability are always subject to change*
      </div>
    </>
  );
};

export default SharedUnitsFooter;
