import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import AllProperties from "./tables/allPropertiesTable";
import { MenuItem, Select } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Swal from "sweetalert2";
import FormControl from "@mui/material/FormControl";
import _debounce from "lodash/debounce";
const initialState = {
  name: "",
  market: "",
  submarket: "",
  category: null,
  street_address: "",
  city: "",
  state: "",
  zip_code: "",
  website: "",
  floorplan_link: "",
  phone: "",
  email_1: "",
  email_2: "",
  email_3: "",
  email_4: "",
  admin_fee: null,
  application_fee: null,
  send: null,
  escort: null,
  bonus: null,
  special: "",
  number_floors: null,
  year_built: null,
  year_renovated: null,
  single_family: null,
  townhome: null,
  high_rise: null,
  number_units: null,
  lat:null,
  lng:null,
  google_rating:null,
  studio: null,
  one_bed: null,
  two_bed: null,
  three_bed: null,
  four_plus_bed: null,
  yard: null,
  yard_location: "",
  loft: null,
  garage: null,
  study: null,
  desk: null,
  ev_charger: null,
  fireplace: null,
  no_carpet: null,
  tub_shower: null,
  industrial: null,
  concrete_floors: null,
  furnished: null,
  high_ceilings: null,
  terrace: null,
  roof_terrace: null,
  floor_to_ceiling_windows: null,
  concierge: null,
  washer_dryer_in_unit: null,
  washer_dryer_connections: null,
  washer_dryer_in_building: null,
  //blacklisted: false,
  smart: "",
  hold_time: "",
  last_line: "",
  one_and_a_half_bath: null,
  studio_price: null,
  one_bed_price: null,
  two_bed_price: null,
  three_bed_price: null,
  four_plus_bed_price: null,
  escort_type: "percentage",
  send_type: "percentage",
  virtual_tour: [],
};
let propertySearchCancelToken;
const Properties = () => {
  let navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const [markets, setMarkets] = useState([]); // Assuming markets come from the properties or are fetched separately
  const [selectedMarkets, setSelectedMarkets] = useState(new Set());
  const [paginationModel, setPaginationModel] = useState(null);
  const [sortModel, setSortModel] = React.useState([]);
  const [selectedFilterCategory, setSelectedFilterCategory] = useState(null);
  const [marketField, setMarketField] = useState([]);
  const [propertyData, setPropertyData] = useState(initialState);
  const [validationError, setValidationError] = useState(false);
  const [openProperty, setOpenProperty] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [driveData, setDriveData] = useState([]);
  useEffect(() => {
    getDriveData();
  }, []);

  const getDriveData = async() => {
    const response = await axios
      .post(`${process.env.REACT_APP_API_URL}/searchFolder`, {
        folderPath: "",
      })
      .then(async (res) => {
        setDriveData(res.data);
        setIsCompleted(true);
        //console.log(res.data, "folder");
      });
  }

  useEffect(() => {
    // Optionally, extract market options from properties
    const marketOptions = new Set(
      properties.map((property) => property.market)
    );
    setMarkets([...marketOptions]);
  }, [properties]);

  const fetchData = _debounce(async () => {
    try {
      const marketArray = [...selectedMarkets];
      const filterData = {
        selectedFilterCategory,
        searchValue,
        sortData: sortModel?.[0],
        filters: { market: marketArray },
        propertyPage: true
      };
      setLoading(true);
      if (propertySearchCancelToken) {
        propertySearchCancelToken.cancel("Operation canceled by the user.");
      }
      propertySearchCancelToken = axios.CancelToken.source();
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/listProperties?page=${paginationModel?.page}&pageSize=${paginationModel?.pageSize}`,
        filterData,
        {
          cancelToken: propertySearchCancelToken?.token,
        }
      );
      // const sortedProperties = result?.data?.rows?.sort(
      //   (a, b) => b.send - a.send
      // );
      setLoading(false);
      //setProperties([]);
      setProperties(result?.data?.rows);
      setTotalRows(result?.data?.totalRows);

      return result;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching properties:", error);
    }
  }, 1000);

  useEffect(() => {
    getFiltersDetails();
  }, []);
  const refreshTable = async (data) => {
    await fetchData();
  }
  const getFiltersDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getFiltersDetails`
      );

      setMarketField(response?.data?.marketFields);
    } catch (error) {
      console.error("Error fetching FiltersDetails:", error);
    }
  };
  const resetPage = () => {
    setPaginationModel((prev) => {
      if (prev && prev?.pageSize) {
        return {
          pageSize: prev?.pageSize,
          page: 0,
        };
      }
      return {
        pageSize: 100,
        page: 0,
      };
    });
  };

  useEffect(() => {
    resetPage();
  }, [selectedFilterCategory, sortModel, selectedMarkets]);

  useEffect(() => {
    if (paginationModel) fetchData();
  }, [paginationModel]);

  useEffect(() => {
    if (searchValue?.length > 2 || searchValue?.length === 0) {
      resetPage();
    }
  }, [searchValue]);
  // useEffect(() => {
  //   // Apply filters based on selected markets
  //   // const currentFilteredProperties = properties.filter((property) =>
  //   //   selectedMarkets.size > 0 ? selectedMarkets.has(property.market) : true
  //   // );

  //   // setFilteredProperties(currentFilteredProperties);
  //   resetPage()
  //   //fetchData()
  // }, [ selectedMarkets]);

  const handleMarketChange = (event) => {
    const { value, checked } = event.target;
    setSelectedMarkets((prev) => {
      const newSet = new Set(prev);
      if (checked) newSet.add(value);
      else newSet.delete(value);
      return newSet;
    });
  };

  // const [filteredData, setFilteredData] = useState([]);
  // useEffect(() => {
  //   setFilteredData(filteredProperties);
  // }, [filteredProperties]);

  // useEffect(() => {
  //   const filtered = filteredProperties?.filter(
  //     (property) => property.category === selectedFilterCategory
  //   );
  //   setFilteredData(filtered);
  // }, [filteredProperties, selectedFilterCategory]);

  const handleCategoryChange = (e) => {
    if (e?.target) {
      const newValue = e?.target?.value;
      setSelectedFilterCategory(newValue);
    }
  };

  const handleChange = (event, field, checkboxValue) => {
    const { name, value } = event.target;
    let newValue;

    if (
      field?.type === "String" &&
      field?.name !== "category" &&
      field?.name !== "building_class"
    ) {
      const newValueOne = value.replace(/^\s+/, "");
      newValue = newValueOne.replace(/^0+/, "");
    } else if (field?.type === "Boolean") {
      newValue =
        propertyData[field.name] === checkboxValue ? null : checkboxValue;
    } else if (field?.type === "Int" || field?.type === "Float") {
      newValue = parseFloat(value);
    } else {
      newValue = value;
    }
    setPropertyData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleAddNewProperty = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/addNewProperty`,
        { propertyData }
      );
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Property added successfully!",
      });
      setPropertyData(initialState);
      await fetchData();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add property. Please try again later.",
      });
      console.error("Error fetching properties:", error);
    }
  };

  const handleCancelProperty = () => {
    setPropertyData(initialState);
    setValidationError(false);
    setOpenProperty(false);
    setVirtualFields([{ name: "", link: "" }]);
  };
  const handleOpenProperty = () => {
    setOpenProperty(true);
  };

  const propertySchema = [
    { name: "name", type: "String", required: true, label: " Name" },
    { name: "virtual_tour_name", type: "String", label: "Virtual Tour Name" },
    { name: "virtual_tour_link", type: "String", label: "Virtual Tour Link" },
    { name: "category", type: "String", label: "Category" },
    { name: "market", type: "String", required: true, label: "Market" },
    { name: "submarket", type: "String", required: true, label: "Submarket" },

    {
      name: "street_address",
      type: "String",
      required: true,
      label: "Street Address",
    },
    { name: "city", type: "String", required: true, label: "City" },
    { name: "state", type: "String", label: "State" },
    { name: "zip_code", type: "String", required: true, label: "Zip Code" },
    { name: "website", type: "String", required: true, label: "Website" },
    {
      name: "floorplan_link",
      type: "String",
      required: true,
      label: "Floorplan Link",
    },
    { name: "phone", type: "String", required: true, label: "Phone" },
    { name: "email_1", type: "String", required: true, label: "Email 1" },
    { name: "email_2", type: "String", label: "Email 2" },
    { name: "email_3", type: "String", label: "Email 3" },
    { name: "email_4", type: "String", label: "Email 4" },
    //{ name: "admin_fee", type: "Float", required: true, label: "Admin Fee" },
    // {
    //   name: "application_fee",
    //   type: "Float",
    //   required: true,
    //   label: "Application Fee",
    // },
    { name: "send", type: "Float", label: "Send" },
    { name: "escort", type: "Float", label: "Escort" },
    { name: "bonus", type: "Float", label: "Bonus" },
    { name: "special", type: "String", label: "Special" },
    {
      name: "number_floors",
      type: "Int",
      required: true,
      label: "Number of Floors",
    },
    { name: "year_built", type: "Int", required: true, label: "Year Built" },
    {
      name: "year_renovated",
      type: "Int",
      label: "Year Renovated",
    },

    { name: "number_units", type: "Int", label: "Number of Units" },

    { name: "yard_location", type: "String", label: "Yard Location" },
    { name: "hold_time", type: "String", label: "Hold Time" },
    { name: "last_line", type: "String", label: "Last Line" },

    // { name: "number_studio", type: "Int", label: "Number  Studios" },
    // { name: "number_one", type: "Int", label: "Number  One " },
    // { name: "number_two", type: "Int", label: "Number  Two " },
    // { name: "number_three", type: "Int", label: "Number  Three" },
    // { name: "number_four", type: "Int", label: "Number  Four" },
    {
      name: "studio_price",
      type: "Float",
      label: "Studio Price",
    },
    {
      name: "one_bed_price",
      type: "Float",
      label: "one bedroom price",
    },
    {
      name: "two_bed_price",
      type: "Float",
      label: "Two bedroom price",
    },
    {
      name: "three_bed_price",
      type: "Float",
      label: "Three bedroom price",
    },
    {
      name: "four_plus_bed_price",
      type: "Float",
      label: "4+ bedroom price",
    },
    {
      name: "lat",
      type: "Float",
      label: "Latitude",
    },
    {
      name: "lng",
      type: "Float",
      label: "Longitude",
    },
    {
      name: "google_rating",
      type: "Float",
      label: "Google Rating",
    },
    { name: "apartment_url", type: "String",  label: "Apartment Url" },
    { name: "notes", type: "String",  label: "Notes" },
    { name: "smart", type: "String", label: " Smart" },
    { name: "studio", type: "Boolean", label: "Studio" },
    { name: "one_bed", type: "Boolean", label: "One Bed" },
    { name: "two_bed", type: "Boolean", label: "Two Bed" },
    { name: "three_bed", type: "Boolean", label: "Three Bed" },
    { name: "four_plus_bed", type: "Boolean", label: "Four or More Bed" },
    { name: "yard", type: "Boolean", label: "Yard" },
    { name: "loft", type: "Boolean", label: "Loft" },
    { name: "garage", type: "Boolean", label: "Garage" },
    { name: "study", type: "Boolean", label: "Study" },
    { name: "desk", type: "Boolean", label: "Desk" },
    { name: "ev_charger", type: "Boolean", label: "EV Charger" },
    { name: "fireplace", type: "Boolean", label: "Fireplace" },
    { name: "no_carpet", type: "Boolean", label: "No Carpet" },
    { name: "tub_shower", type: "Boolean", label: "Tub/Shower" },
    { name: "industrial", type: "Boolean", label: "Industrial" },
    { name: "concrete_floors", type: "Boolean", label: "Concrete Floors" },
    { name: "furnished", type: "Boolean", label: "Furnished" },
    { name: "high_ceilings", type: "Boolean", label: "High Ceilings" },
    { name: "terrace", type: "Boolean", label: "Terrace" },
    { name: "roof_terrace", type: "Boolean", label: "Roof Terrace" },
    {
      name: "floor_to_ceiling_windows",
      type: "Boolean",
      label: "Floor-to-Ceiling Windows",
    },
    { name: "concierge", type: "Boolean", label: "Concierge" },
    {
      name: "washer_dryer_in_unit",
      type: "Boolean",
      label: "Washer/Dryer in Unit",
    },
    {
      name: "washer_dryer_connections",
      type: "Boolean",
      label: "Washer/Dryer Connections",
    },
    {
      name: "washer_dryer_in_building",
      type: "Boolean",
      label: "Washer/Dryer in Building",
    },
    {
      name: "one_and_a_half_bath",
      type: "Boolean",
      label: "1.5 bath",
    },

    {
      name: "single_family",
      type: "Boolean",
      label: "Single Family",
    },
    {
      name: "high_rise",
      type: "Boolean",
      label: "High Rise",
    },
    {
      name: "townhome",
      type: "Boolean",
      label: "TownHome",
    },
    // {
    //   name: "blacklisted",
    //   required: true,
    //   type: "Boolean",
    //   label: "Blacklisted",
    // },
    // {
    //   name: "blacklisted",
    //   required: true,
    //   type: "Boolean",
    //   label: "Blacklisted",
    // },
  ];
  const [virtualFields, setVirtualFields] = useState([{ name: "", link: "" }]);

  const isValidEmail = (email) => {
    if (email) {
      const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
      return emailRegex.test(email);
    }
    return true;
  };
  const [isPhoneValid, setIsValidPhone] = useState(true);
  const handleSubmit = () => {
    const requiredFields = propertySchema.every((field) => {
      if (field.required) {
        return (
          propertyData[field.name] !== "" &&
          propertyData[field.name] !== null &&
          propertyData[field.name] !== undefined
        );
      }
      return true;
    });

    const emailFieldsValid = propertySchema.every((field) => {
      if (
        field.type === "String" &&
        field.name.startsWith("email_") &&
        field.name !== "email_info"
      ) {
        return isValidEmail(propertyData[field.name]);
      }
      return true;
    });

    const phoneRegex = /^\d+$/;
    if (propertyData.phone && !phoneRegex.test(propertyData?.phone)) {
      setIsValidPhone(false);
    } else {
      setIsValidPhone(true);
    }

    if (requiredFields && emailFieldsValid && isPhoneValid) {
      setValidationError(false);
      handleAddNewProperty();
      handleCancelProperty();
    } else {
      setValidationError(true);
    }
  };
  const handleVirtualFieldChange = (event, index, fieldName) => {
    const newVirtualFields = [...virtualFields];
    newVirtualFields[index][fieldName?.slice(13)] = event.target.value
      .trim()
      .replace(/^0+/, "");

    setVirtualFields(newVirtualFields);
  };
  const addVirtualField = () => {
    setVirtualFields([...virtualFields, { name: "", link: "" }]);
  };
  const deleteVirtualField = (index) => {
    const newVirtualFields = [...virtualFields];
    newVirtualFields.splice(index, 1);
    setVirtualFields(newVirtualFields);
  };
  useEffect(() => {
    const filteredVirtualFields = virtualFields.filter((field) => field?.link);

    setPropertyData((prev) => ({
      ...prev,
      virtual_tour: filteredVirtualFields,
    }));
  }, [virtualFields]);
  const renderField = (field) => {
    switch (field.type) {
      case "String":
        if (field.name === "category") {
          return (
            <FormControl className="mt-2" fullWidth margin="dense">
              <InputLabel id="demo-simple-select-label">
                {field.label}
              </InputLabel>
              <Select
                key={field.name}
                helperText={
                  field.required &&
                  validationError &&
                  propertyData[field.name] === ""
                    ? `Please Enter ${field.name}`
                    : ""
                }
                label={field.label}
                name={field.name}
                value={propertyData[field.name]}
                onChange={(event) => handleChange(event, field)}
                fullWidth
                margin="dense"
                required={field.required}
              >
                <MenuItem style={{ color: "#396fd4" }} value={null}>
                  --Select a Category--
                </MenuItem>
                <MenuItem value="Not working with locators">
                  Not working with locators
                </MenuItem>
                <MenuItem value="Student Housing">Student Housing</MenuItem>
                <MenuItem value="Affordable Housing">
                  Affordable Housing
                </MenuItem>
                <MenuItem value="Do not add">Do not add</MenuItem>
                <MenuItem value="Unsure">Unsure</MenuItem>
              </Select>
            </FormControl>
          );
        } else if (
          ["email_1", "email_2", "email_3", "email_4"].includes(field.name)
        ) {
          return (
            <TextField
              helperText={
                validationError ? (
                  field.required && propertyData[field.name] === "" ? (
                    <span className="text-red-500">Please enter an email</span>
                  ) : !isValidEmail(propertyData[field.name]) ? (
                    <span className="text-red-500">
                      Please enter a valid email
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              }
              key={field.name}
              className="w-[350px]"
              name={field.name}
              label={field.label}
              type="email"
              value={propertyData[field.name]}
              onChange={(event) => handleChange(event, field)}
              margin="dense"
              required={field.required}
            />
          );
        } else if (field.name === "phone") {
          return (
            <TextField
              key={field.name}
              helperText={
                field.required &&
                validationError &&
                propertyData[field.name] === "" ? (
                  <span className="text-red-500">
                    Please Enter Your {field.label}
                  </span>
                ) : field.required && validationError && !isPhoneValid ? (
                  <span className="text-red-500">
                    Please Enter Valid {field.label}
                  </span>
                ) : (
                  ""
                )
              }
              className="w-[350px]"
              name={field.name}
              label={field.label}
              type="text"
              value={propertyData[field.name]}
              onChange={(event) => handleChange(event, field)}
              margin="dense"
              required={field.required}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
            />
          );
        } else if (
          field?.name === "virtual_tour_name" ||
          field?.name === "virtual_tour_link"
        ) {
          return (
            <>
              {virtualFields.map((virtualField, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    key={`${field?.name}-${index}`}
                    className="w-[350px]"
                    name={`${field?.name}-${index}`}
                    label={`${field?.label} ${index + 1}`}
                    type="text"
                    value={virtualField[field.name]}
                    onChange={(event) =>
                      handleVirtualFieldChange(event, index, field?.name)
                    }
                    margin="dense"
                    required={field?.required}
                  />
                  {field.name === "virtual_tour_link" && (
                    <IconButton onClick={() => deleteVirtualField(index)}>
                      <DeleteIcon style={{ color: "red" }} />
                    </IconButton>
                  )}
                </div>
              ))}
            </>
          );
        } else {
          return (
            <TextField
              helperText={
                field.required &&
                validationError &&
                propertyData[field.name] === "" ? (
                  <span className="text-red-500">
                    Please Enter {field.label}
                  </span>
                ) : (
                  ""
                )
              }
              key={field.name}
              className="w-[350px]"
              name={field.name}
              label={field.label}
              type="text"
              value={propertyData[field.name]}
              onChange={(event) => handleChange(event, field)}
              margin="dense"
              required={field.required}
            />
          );
        }

      case "Int":
      case "Float":
        if (field.name === "lat" || field.name === "lng" || field.name === "google_rating") {
          return (
            <>
              <TextField
                key={field.name}
                helperText={
                  field.required &&
                  validationError &&
                  (propertyData[field.name] === null ||
                    isNaN(propertyData[field.name])) ? (
                    <span className="text-red-500">
                      Please Enter {field.label}
                    </span>
                  ) : (
                    ""
                  )
                }
                className="w-[350px]"
                name={field.name}
                label={field.label}
                type="number"
                value={propertyData[field.name]}
                onChange={(event) => handleChange(event, field)}
                margin="dense"
                required={field.required}
              />
            </>
          );
        } else {
        return (
          <>
          
            <TextField
              key={field.name}
              helperText={
                field.required &&
                validationError &&
                (propertyData[field.name] === null ||
                  isNaN(propertyData[field.name])) ? (
                  <span className="text-red-500">
                    Please Enter {field.label}
                  </span>
                ) : (
                  ""
                )
              }
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
              className="w-[350px]"
              name={field.name}
              label={field.label}
              type="number"
              value={propertyData[field.name]}
              onChange={(event) => handleChange(event, field)}
              margin="dense"
              required={field.required}
            />
          </>
        );
      }

      case "Boolean":
        return (
          <FormControl
            className="mt-2"
            fullWidth
            margin="dense"
            required={field.required}
          >
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={propertyData[field.name] === true}
                    onChange={(event) => handleChange(event, field, true)}
                    name={field.name}
                    color="primary"
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={propertyData[field.name] === false}
                    onChange={(event) => handleChange(event, field, false)}
                    name={field.name}
                    color="primary"
                  />
                }
                label="No"
              />
              {field.required &&
              validationError &&
              propertyData[field.name] === null ? (
                <span className="text-red-500 text-[14px]">
                  Please Enter {field.label}
                </span>
              ) : (
                ""
              )}
            </div>
          </FormControl>
        );

      default:
        return null;
    }
  };
  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    const fieldName = `${name}_type`;
    const fieldValue = value === "%" ? "percentage" : "dollar";
    setPropertyData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const renderRadioButtons = (fieldName) => {
    if (["send", "escort"].includes(fieldName)) {
      return (
        <>
          <div className="flex gap-1">
            <input
              type="radio"
              id={`${fieldName}_option1`}
              name={fieldName}
              value="%"
              checked={propertyData[`${fieldName}_type`] === "percentage"}
              onChange={handleRadioChange}
            />
            <label
              className="text-slate-900 font-medium"
              htmlFor={`${fieldName}_option1`}
            >
              %
            </label>
            <input
              type="radio"
              id={`${fieldName}_option2`}
              name={fieldName}
              value="$"
              checked={propertyData[`${fieldName}_type`] === "dollar"}
              onChange={handleRadioChange}
            />
            <label
              className="text-slate-900 font-medium"
              htmlFor={`${fieldName}_option2`}
            >
              $
            </label>
          </div>
        </>
      );
    } else if (fieldName === "virtual_tour_link") {
      return (
        <div className="m-0">
          <IconButton onClick={() => addVirtualField()}>
            <AddIcon style={{ color: "#0075FF" }} />
          </IconButton>
        </div>
      );
    }
    return null;
  };

  const handleSearchChange = (event) => {
    const value = event?.target?.value;
    setSearchValue(value || "");
  };
  const handleUploadClick = () => {
    navigate("/admin");
  };

  const handleUploadApartmentUrlClick = () => {
    navigate("/admin-apartment-url");
  };

  const handleUploadAllFieldsClick = () => {
    window.open("/update-property", "_blank");
  };

  return (
    <div className=" mx-auto h-full">
      <div className="flex px-4 pt-4">
        <div className="  h-3/4 mr-10">
          <h3 style={{ marginTop: 1, fontWeight: "bold" }}>Search</h3>
          <input
            value={searchValue}
            onChange={handleSearchChange}
            className="mt-[14px] h-[53px] w-[310px] rounded-md border border-black/30 bg-transparent px-3 py-2 text-lg placeholder:text-gray-600 focus:outline-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
            type="text"
            placeholder="   Search by name"
          ></input>
        </div>
        <div className=" mr-10">
          <h3 style={{ marginTop: 1, fontWeight: "bold" }}>Location</h3>
          <Box className="grid grid-cols-2 gap-4 mt-[10px] ">
            {marketField.map((market) => (
              <div key={market}>
                <input
                  type="checkbox"
                  id={`checkbox-${market}`}
                  name="market"
                  value={market}
                  onChange={handleMarketChange}
                  checked={selectedMarkets.has(market)}
                />
                <label className="ml-2" htmlFor={`checkbox-${market}`}>
                  {market}
                </label>
              </div>
            ))}
          </Box>
        </div>
        <Box className="flex flex-row justify-between">
          <Box>
            <h3 style={{ marginTop: 1, fontWeight: "bold" }}>Category</h3>
            <Box sx={{ minWidth: 20, marginBottom: 2 }}>
              <FormControl sx={{ marginTop: 2 }}>
                {selectedFilterCategory !== null && (
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                )}
                <Select
                  className="w-25 "
                  displayEmpty
                  inputProps={{ "aria-label": "Category" }}
                  value={selectedFilterCategory ?? "null"}
                  label={selectedFilterCategory !== null ? "Category" : ""}
                  onChange={(e) => handleCategoryChange(e)}
                >
                  <MenuItem style={{ color: "#396fd4" }} value={null}>
                    --Select a Category--
                  </MenuItem>
                  <MenuItem value="Not working with locators">
                    Not working with locators
                  </MenuItem>
                  <MenuItem value="Student Housing">Student Housing</MenuItem>
                  <MenuItem value="Affordable Housing">
                    Affordable Housing
                  </MenuItem>
                  <MenuItem value="Do not add">Do not add</MenuItem>
                  <MenuItem value="Unsure">Unsure</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 5,
          marginBottom: 2,
          padding:1
        }}
      >
        <Button variant="outlined" onClick={handleOpenProperty}>
          Add New Property
        </Button>
        <Button
          sx={{ display: "flex", justifyContent: "flex-end", height: 40 }}
          variant="contained"
          color="primary"
          onClick={handleUploadClick}
        >
          Upload New Property
        </Button>
        {/* <Button
          sx={{ display: "flex", justifyContent: "flex-end", height: 40 }}
          variant="contained"
          color="primary"
          onClick={handleUploadApartmentUrlClick}
        >
          Upload Apartment Url
        </Button> */}
        <Button
          sx={{ display: "flex", justifyContent: "flex-end", height: 40 }}
          variant="contained"
          color="primary"
          onClick={handleUploadAllFieldsClick}
        >
          Update Existing Property
        </Button>
      </Box>
      {properties.length > 0 && refresh ? (
        <AllProperties
          driveData={driveData}
          properties={properties}
          setFilteredProperties={setProperties}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          totalRows={totalRows}
          sortModel={sortModel}
          setSortModel={setSortModel}
          fetchData={fetchData}
          loading={loading}
          refresh={refreshTable}
        />
      ) : (
        <p>No results please adjust your filters</p>
      )}

      <Dialog open={openProperty} onClose={handleCancelProperty} maxWidth="lg">
        <DialogTitle>Add New Property</DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            {propertySchema.map((field) => (
              <Grid item xs={4} key={field.name}>
                <InputLabel
                  className={`${
                    field?.name === "virtual_tour_name" ||
                    field?.name === "name"
                      ? "mt-[11px] mb-1"
                      : ""
                  }`}
                  sx={{
                    color: "cadetblue",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  id={`${field.name}-label`}
                >
                  {field.label}:
                  <span style={{ marginRight: 10 }} sx={{ color: "blue" }}>
                    {renderRadioButtons(field.name)}
                  </span>
                </InputLabel>
                {renderField(field)}
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelProperty} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Properties;
