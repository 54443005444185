import React from "react";
import InstagramPost from "./InstagramPost";
import bgImage from "../../images/virtual_tour_bg.png";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import YoutubeRenderer from "./YoutubeRenderer";

const VirtualTourRenderer = ({ data = "" }) => {
  return (
    <div className="w-full h-full max-h-80">
      {data && data?.includes("instagram") ? (
        <InstagramPost postUrl={data} />
      ) : data && data?.includes("youtube") ? (
        <YoutubeRenderer videoUrl={data} />
      ) : (
        <div
          className="relative w-full h-full bg-cover"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 rounded-lg">
            <a
              href={data || "#"}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white absolute px-4 py-2 rounded-md text-sm font-semibold"
            >
              <div className="flex items-center justify-center">
                <div>Virtual Tour</div>
                <OpenInNewIcon />
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default VirtualTourRenderer;
