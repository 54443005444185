// components/Searches.js
import React, { useState, useEffect } from "react";
import useStore from "../store";
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid";
import Link from "@mui/material/Link";
import StarsIcon from "@mui/icons-material/Stars";
import axios from "axios";
import {
  Box,
  Button,
  Snackbar,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Checkbox,
  FormControlLabel,
  Slider,
  Autocomplete,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  // Restore as RestoreIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import { Popper, Paper, InputBase } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import EditClientInfo from "./EditSearch";

const initialFilters = {
  market: [],
  // For Units group - initialized as booleans
  studio: false,
  one_bed: false,
  two_bed: false,
  three_bed: false,
  four_plus_bed: false,

  // For Amenities group - initialized as booleans
  yard: false,
  loft: false,
  garage: false,
  townhome: false,
  one_and_a_half_bath: false,
  studio_price: [],
  one_bed_price: [],
  two_bed_price: [],
  three_bed_price: [],
  four_plus_bed_price: [],
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const booleanFieldGroups = {
  Units: ["studio", "one_bed", "two_bed", "three_bed", "four_plus_bed"],
  Amenities: ["yard", "loft", "garage", "townhome"],
};
const marketFieldGroup = {
  Market: ["DFW", "ATX"],
};

const Searches = () => {
  const user = useStore((state) => state.user);

  const [savedSearches, setSavedSearches] = useState([]);
  const [idArray, setIdArray] = useState([]);
  const [paginationModel, setPaginationModel] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [buildingClass, setBuildingClass] = useState([]);
  const [marketField, setMarketField] = useState([]);
  const [priceRangeFilter, setPriceRangeFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 45000]);
  const [searchValue, setSearchValue] = useState("");
  const [sortModel, setSortModel] = useState([{ field: "id", sort: "asc" }]);
  const [editModal, setEditModal] = useState({ show: false, clientId: "" });
  // const [updatedRowId, setUpdatedRowId] = useState(null);

  useEffect(() => {
    if (searchValue?.length > 2 || searchValue?.length === 0) {
      resetPage();
    }
  }, [searchValue]);

  useEffect(() => {
    getFiltersDetails();
  }, []);

  useEffect(() => {
    resetPage();
  }, [sortModel, filters, selectedMonth, priceRange, selectedDate]);

  useEffect(() => {
    if (paginationModel) fetchSavedSearches();
  }, [paginationModel]);

  const formatLabel = (label) =>
    label
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word?.slice(1))
      .join(" ");

  const resetPage = () => {
    setPaginationModel((prev) => {
      if (prev && prev?.pageSize) {
        return {
          pageSize: prev?.pageSize,
          page: 0,
        };
      }
      return {
        pageSize: 100,
        page: 0,
      };
    });
  };

  const handleFilterChange = (event) => {
    try {
      const { name, value, checked } = event.target;
      if (name === "ATX" || name === "DFW") {
        if (checked) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            market: [...prevFilters.market, name],
          }));
        } else {
          setFilters((prevFilters) => ({
            ...prevFilters,
            market: prevFilters.market.filter((item) => item !== name),
          }));
        }
      }
      if (Array.isArray(filters[name])) {
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
      } else {
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
      }

      if (typeof filters[name] === "boolean") {
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: checked,
        }));
      } else if (Array.isArray(filters[name])) {
        // For array-based filters
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: event.target.value,
        }));
      } else {
        // For other types of filters
        setFilters((prevFilters) => ({
          ...prevFilters,
          [name]: event.target.value,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value || "");
  };

  const handleMonthChange = (_, newValue) => {
    setSelectedMonth(newValue);
  };

  const handleSliderChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const handleSelectionChange = (selectionModel) => {
    setIdArray(selectionModel);
  };

  const handleCellClick = (params, event) => {
    if (params.field === "black") {
      event.stopPropagation();
    }
  };

  const handleEditClient = (data) => {
    setEditModal({ show: true, clientId: data?.client_id || "" });
  };

  const updateClientInfo = (newData) => {
    if (!newData || typeof newData !== "object") return;
    try {
      setSavedSearches((prev) =>
        prev.map((item) =>
          Number(item?.client_id) === Number(newData?.client_id) ? { ...item, ...newData } : item
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getFiltersDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getFiltersDetails`
      );

      setBuildingClass(response?.data?.buildingClasses);
      setMarketField(response?.data?.marketFields);
    } catch (error) {
      console.log("Error fetching FiltersDetails:", error);
    }
  };

  const fetchSavedSearches = async () => {
    try {
      const filterData = {
        selectedMonth,
        selectedDate,
        searchValue,
        sortData: sortModel?.at(0),
        filterData: filters,
        priceRange,
      };
      // if(!paginationModel?.page || !paginationModel?.pageSize || !user.id) return
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/allSearches?page=${paginationModel?.page}&pageSize=${paginationModel?.pageSize}&id=${user?.id}`,
        filterData
      );
      setSavedSearches(response?.data?.searches || []);
      setTotalRows(response?.data?.totalRows || 0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error fetching saved searches:", error);
    }
  };

  const renderCellContent = (params) => {
    const { field, value, row } = params;
    if (field === "category" && (value === null || value === undefined)) {
      return "-";
    }
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      return JSON.stringify(value, null, 2); // Pretty print JSON object
    }

    if (field === "firstname" && row?.floorplan_link) {
      return (
        <Link
          href={row?.floorplan_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </Link>
      );
    }

    if (field === "no_of_bedrooms") {
      return value?.join(", ");
    }
    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    }
    if (typeof value === "string") {
      // Check for URL
      try {
        const url = new URL(value);
        if (url.protocol === "http:" || url.protocol === "https:") {
          return (
            <Link href={value} target="_blank" rel="noopener noreferrer">
              {value}
            </Link>
          );
        }
      } catch (error) {
        // Not a URL
      }
      // Check for email
      if (value.includes("@") && value.includes(".")) {
        return <Link href={`mailto:${value}`}>{value}</Link>;
      }
      // Check for phone numbers (basic validation)
      const phoneRegex = /^\d{10}$/;
      if (
        phoneRegex.test(value.replace(/[\s\-\(\)]/g, "")) &&
        value.replace(/[\s\-\(\)]/g, "").length === 10
      ) {
        return <Link href={`tel:${value}`}>{value}</Link>;
      }
    }
    return value;
  };

  const renderStartButton = (params) => {
    if (params?.row?.is_form) {
      return <StarsIcon style={{ color: "blue" }} />;
    }
  };

  const renderActions = (params) => {
    return (
      <div className="flex items-center gap-4">
        <Tooltip title="Edit">
          <IconButton
            size="small"
            onClick={() => {
              handleEditClient(params?.row);
            }}
            sx={{
              // ml: 1,
              backgroundColor: "#E3F2FD",
              "&:hover": { backgroundColor: "#BBDEFB" },
            }}
          >
            <EditIcon sx={{ color: "#1976D2" }} />
          </IconButton>
        </Tooltip>
        <IconButton
          onClick={async (e) => {
            const response = await axios
              .delete(
                `${process.env.REACT_APP_API_URL}/deleteSearch/${params?.id}`
              )
              .then(() => {
                fetchSavedSearches();
              });
          }}
          sx={{
            // ml: 1,
            backgroundColor: "#FFEBEE",
            "&:hover": { backgroundColor: "#FFCDD2" },
          }}
          variant="contained"
          color="error"
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  // const renderDeleteButton = (params) => {
  //   return (
  //     <Button
  //       onClick={async (e) => {
  //         console.log(params);
  //         const response = await axios
  //           .delete(
  //             `${process.env.REACT_APP_API_URL}/deleteSearch/${params?.id}`
  //           )
  //           .then(() => {
  //             fetchSavedSearches();
  //           });
  //       }}
  //       variant="contained"
  //       color="primary"
  //       size="small"
  //     >
  //       <DeleteIcon />
  //     </Button>
  //   );
  // };

  const EditMultilineText = (props) => {
    const { id, field, value, colDef } = props;
    const [valueState, setValueState] = useState(
      value ? JSON.stringify(value) : ""
    );
    const [anchorEl, setAnchorEl] = useState();
    const [inputRef, setInputRef] = useState(null);
    const apiRef = useGridApiContext();

    const handleRef = (el) => {
      setAnchorEl(el);
    };

    const handleChange = (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event
      );
    };

    return (
      <div style={{ position: "relative", alignSelf: "flex-start" }}>
        <div
          ref={handleRef}
          style={{
            height: 1,
            width: colDef.computedWidth,
            display: "block",
            position: "absolute",
            top: 0,
          }}
        />
        {anchorEl && (
          <Popper open anchorEl={anchorEl} placement="bottom-start">
            <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
              <InputBase
                multiline
                rows={4}
                value={valueState}
                sx={{
                  textarea: { resize: "both", fontSize: "15px" },
                  width: "100%",
                }}
                onChange={handleChange}
                inputRef={(ref) => setInputRef(ref)}
              />
            </Paper>
          </Popper>
        )}
      </div>
    );
  };

  const multilineColumn = {
    type: "string",
    renderEditCell: (params) => <EditMultilineText {...params} />,
  };

  const generateColumns = () => {
    let orderedColumns = [];

    orderedColumns.push({
      field: "star",
      headerName: "",
      width: 10,
      sortable: false,
      renderCell: renderStartButton,
      disableClickEventBubbling: true,
    });

    if (savedSearches && savedSearches?.length > 0) {
      Object.keys(savedSearches[0])?.forEach((key) => {
        let columnConfig = {
          field: key,
          headerName:
            key.charAt(0).toUpperCase() + key?.slice(1)?.replace(/_/g, " "),

          width: 200,
          renderCell: renderCellContent,
          ...(key === "filters" ? { ...multilineColumn } : {}),
        };

        orderedColumns.push(columnConfig);
      });
    }
    orderedColumns.push({
      field: "black",
      headerName: "Action",
      width: 100,
      renderCell: renderActions,
    });

    return orderedColumns;
  };


  return (
    <div className=" mx-auto py-4 pb-11 bg-white rounded-lg">
      <Box sx={{ height: "80vh", width: "100%" }}>
        <h2 className="text-2xl font-bold mb-4 px-4">Past Searches</h2>
        <div className="p-4 flex flex-row gap-10">
          <input
            value={searchValue}
            onChange={handleSearchChange}
            className=" h-10 w-[310px] rounded-3xl border border-black/30 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600  focus:outline-blue-500 disabled:cursor-not-allowed disabled:opacity-50"
            type="text"
            placeholder="  Search by Client Name"
          ></input>
        </div>
        <div className="p-4 flex flex-row gap-10">
          {" "}
          {Object.entries(marketFieldGroup).map(([groupName, fields]) => (
            <Box key={groupName} sx={{ mb: 2 }}>
              <h3>{groupName}</h3>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {fields.map((field) => (
                  <FormControlLabel
                    key={field}
                    control={
                      <Checkbox
                        checked={filters.market.includes(field)}
                        onChange={handleFilterChange}
                        name={field}
                        sx={{ fontSize: "0.8rem" }}
                      />
                    }
                    label={formatLabel(field)}
                    sx={{ fontSize: "0.8rem" }}
                  />
                ))}
              </Box>
            </Box>
          ))}
          {Object.entries(booleanFieldGroups).map(([groupName, fields]) => (
            <Box key={groupName} sx={{ mb: 2 }}>
              <h3>{groupName}</h3>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {fields.map((field) => (
                  <FormControlLabel
                    key={field}
                    control={
                      <Checkbox
                        checked={filters[field] || false}
                        onChange={handleFilterChange}
                        name={field}
                        sx={{ fontSize: "0.8rem" }}
                      />
                    }
                    label={formatLabel(field)}
                    sx={{ fontSize: "0.8rem" }}
                  />
                ))}
              </Box>
            </Box>
          ))}
          <Box className="basis-1/6" sx={{ width: "45%", mb: 2, px: 2 }}>
            Price Range
            <Slider
              value={priceRange}
              valueLabelDisplay="auto"
              min={0}
              max={20000}
              onChange={handleSliderChange}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <span>{priceRange[0]}</span>
              <span>{priceRange[1]}</span>
            </Box>
          </Box>
          {/* <Box className="basis-1/6 ml-10" sx={{ mb: 2 }}>
            <FormControl variant="outlined" sx={{ width: 210, marginTop: 1 }}>
              <Autocomplete
                value={selectedMonth}
                onChange={handleMonthChange}
                options={months}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Move in Month"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Box> */}
          <Box className="basis-1/6" sx={{ mb: 2 }}>
            <FormControl variant="outlined" sx={{ width: 210, marginTop: 1 }}>
              <TextField
                label="Move in Date"
                type="date"
                value={selectedDate}
                id="selectedDate"
                variant="outlined"
                onChange={(e) => setSelectedDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Box>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            width: "100%",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Tooltip title="Refresh">
            <IconButton
              size="small"
              onClick={() => {
                fetchSavedSearches();
              }}
              sx={{
                backgroundColor: "#E3F2FD",
                "&:hover": { backgroundColor: "#BBDEFB" },
              }}
            >
              <RefreshIcon sx={{ color: "#1976D2" }} />
            </IconButton>
          </Tooltip>
          <Button
            onClick={async (e) => {
              const response = await axios
                .post(`${process.env.REACT_APP_API_URL}/deleteSearchGroup`, {
                  searchId: idArray,
                })
                .then(() => {
                  fetchSavedSearches();
                });
            }}
            variant="contained"
            color="primary"
            size="small"
            disabled={idArray?.length > 0 ? false : true}
          >
            Delete
          </Button>
        </div>
        {savedSearches && savedSearches?.length > 0 ? (
          <DataGridPro
            onCellClick={handleCellClick}
            initialState={{
              pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
                right: ["black"],
              },
            }}
            rows={savedSearches.map((property, index) => ({
              id: property.id || index,
              ...property,
            }))}
            columns={generateColumns()}
            // getRowClassName={getRowClassName}
            pageSize={paginationModel.pageSize}
            checkboxSelection
            onRowSelectionModelChange={(newSelectionModel) =>
              handleSelectionChange(newSelectionModel)
            }
            components={{
              Toolbar: GridToolbar,
            }}
            experimentalFeatures={{ newEditingApi: true }}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={(e) => {
              setPaginationModel((prev) => {
                if (e.pageSize != prev?.pageSize) {
                  return {
                    page: 0,
                    pageSize: e.pageSize,
                  };
                }
                return e;
              });
            }}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            rowCount={totalRows}
            loading={loading}
            getRowClassName={(params) => {
              const currentRowIndex =
                savedSearches && savedSearches?.length > 0
                  ? savedSearches.findIndex((row) => row.id === params.id)
                  : 0;
              return currentRowIndex % 2 === 0 ? "even-row" : "odd-row";
            }}
            {...savedSearches}
          />
        ) : (
          <p>No results please adjust your filters</p>
        )}
      </Box>
      <EditClientInfo
        open={editModal?.show}
        clientId={editModal?.clientId}
        setEditModal={setEditModal}
        updateClientInfo={updateClientInfo}
      />
    </div>
  );
};

export default Searches;
