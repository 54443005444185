import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Modal, Tabs, Tag, Timeline, Empty } from "antd";
import {
  ClockCircleOutlined,
  DesktopOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import EditSharedUnits from "./EditSharedUnits";
import { Button, IconButton, Tooltip } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Refresh as RefreshIcon } from "@mui/icons-material";

const ClientPortalAnalytics = (props) => {
  const {
    analytics = false,
    setAnalytics = () => {},
    clientId = "",
  } = props || {};

  const [timelineData, setTimelineData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    console.log("rararararraraaar");
    if (clientId && analytics) {
      getClientVisitData();
    }
  }, [clientId, analytics]);

  const onChange = (key) => {
    console.log(key);
  };

  const handleClose = () => {
    setAnalytics(false);
    setTimelineData([]);
  };

  const getClientVisitData = async () => {
    setDataLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/shared-links/client-portal-visits/${clientId}`
      )
      .then((response) => response.data)
      .then((result) => {
        if (result?.data && result?.data?.length) {
          setTimelineData(result?.data);
        } else {
          setTimelineData([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setTimelineData([]);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  // const mappedTimelineItems = timelineData && timelineData?.length ?  timelineData?.map((item, index) => ({
  //   children: `${item?.route} - ${new Date(item?.visited_at).toLocaleString()}`,
  //   dot: <ClockCircleOutlined style={{ fontSize: "16px", color: "#1890ff" }} />,
  // })) : []

  const mappedTimelineItems =
    timelineData && timelineData?.length
      ? timelineData.map((item, index) => ({
          children: (
            <Card key={index} className="w-full shadow-md">
              <p className="text-sm font-semibold">
                <ClockCircleOutlined /> Visited at:{" "}
                {item?.visited_at
                  ? new Date(item?.visited_at)?.toLocaleString()
                  : ""}
              </p>

              {/* Device Info */}
              {item?.device_info ? (
                <div className="mt-2">
                  <Tag color="blue">
                    <DesktopOutlined /> Browser:{" "}
                    {item?.device_info?.browser || "Unknown"}
                  </Tag>
                  <Tag color="green">
                    Platform: {item?.device_info?.platform || "Unknown"}
                  </Tag>
                  <Tag color="cyan">
                    Language: {item?.device_info?.language || "Unknown"}
                  </Tag>
                </div>
              ) : (
                <Tag color="red" className="mt-2">
                  No Device Info Available
                </Tag>
              )}

              {/* Location Info */}
              {item?.location_info ? (
                <div className="mt-2">
                  <Tooltip
                    title={`Accuracy: ${item?.location_info.accuracy.toFixed(
                      2
                    )} meters`}
                  >
                    <Tag color="purple">
                      <GlobalOutlined /> Lat:{" "}
                      {item?.location_info.latitude.toFixed(6)}, Lon:{" "}
                      {item?.location_info.longitude.toFixed(6)}
                    </Tag>
                  </Tooltip>
                </div>
              ) : (
                <Tag color="red" className="mt-2">
                  No Location Info Available
                </Tag>
              )}
            </Card>
          ),
          // dot: <ClockCircleOutlined style={{ fontSize: "16px", color: "#1890ff" }} />,
          color: "green",
        }))
      : [];

  const tabItems = [
    {
      label: "Shared Units",
      key: 1,
      children: (
        <div className="w-full h-full min-h-[300px] max-h-[450px] overflow-y-auto">
          <EditSharedUnits clientId={clientId} />
        </div>
      ),
    },
    {
      label: "Visit Logs",
      key: 2,
      children: (
        <div className="w-full h-full min-h-[300px] max-h-[450px] overflow-y-auto">
          {dataLoading ? (
            <div className="text-center p-4">Loading visit logs...</div>
          ) : (
            <div className="p-4">
              <div className="flex items-center justify-end my-4">
                <Tooltip title="Refresh">
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (clientId && analytics) {
                        getClientVisitData();
                      }
                    }}
                    sx={{
                      backgroundColor: "#E3F2FD",
                      "&:hover": { backgroundColor: "#BBDEFB" },
                    }}
                  >
                    <RefreshIcon sx={{ color: "#1976D2" }} />
                  </IconButton>
                </Tooltip>
              </div>
              {mappedTimelineItems && mappedTimelineItems?.length ? (
                <Timeline items={mappedTimelineItems} />
              ) : (
                <div style={{ padding: "20px", textAlign: "center" }}>
                  <Empty description="No Data Available" />
                </div>
              )}
            </div>
          )}
        </div>
      ),
    },
  ];

  console.log({ timelineData });
  return (
    <div className="w-full h-full">
      <Modal
        width={720}
        open={analytics}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Client Portal</span>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={handleClose}
            />
          </div>
        }
        footer={null}
        onClose={handleClose}
        onCancel={handleClose}
        destroyOnClose
      >
        {/* <div className="w-full h-full"> */}
        <Tabs
          className="w-full h-full"
          onChange={onChange}
          type="card"
          items={tabItems}
        />
        {/* </div> */}
      </Modal>
    </div>
  );
};

export default ClientPortalAnalytics;
