import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function SidekickAccordion({
  index,
  openIndex,
  setOpenIndex,
  title = "",
  content = "",
}) {
  const isOpen = openIndex === index;

  return (
    <div>
      <Accordion
        expanded={isOpen}
        onChange={() => setOpenIndex(isOpen ? null : index)}
        sx={{ boxShadow: "none" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-${index}-content`}
          id={`panel-${index}-header`}
        >
          <span className="font-inter text-[#737373] text-[12px] md:text-[14px]">
            {title}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <span className="font-inter text-[#737373] text-xs md:text-[14px]">
            {content}
          </span>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
